import { React, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Row, Col, Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx'
import { updateRegistrationStep } from '../../../features/auth/authSlice'
import { useNavigate } from 'react-router-dom'
import tick from '../../images/tick.png'
import tickOutlined from '../../images/tick-outlined.png'
import Logo from '../../images/ScopeHaiLogo-two-color.png';
import { ArrowRight, User, GraduationCap, BriefcaseBusiness, Check, X, AlignCenter, ArrowLeft } from 'lucide-react';
import { GetCandidateByID, fetchAllJobRoles, fetchAllJobRolesSkills, fetchAllSkills, postUserImage, postUserCertificates } from '../../../features/constants/apis'
import Select from 'react-select';
import SideValiDidi from '../SideValiDidi';

const CandidateSignup5 = ({
    candidateAuth,
    onSendData,
    authToken,
    tokenData,
    changeForm,
    submitFormStep2,
    completeProfileStep,
    updateRegistrationStep,
    ID,
    fullName,
    userData,
    role
}) => {
    console.log('candidateAuth', candidateAuth);

    const [validated, setValidated] = useState(false)
    const [selectGSkills, setSelectGSkills] = useState([])
    const [selectPSkills, setSelectPSkills] = useState([])
    const [uploadedFile, setUploadedFile] = useState([])
    const [fileUploaded, setFileUploaded] = useState(false)
    const [selectNotification, setSelectNotification] = useState(false)
    const [candidateData, setCandidateData] = useState([])
    const [jobRoles, setJobRoles] = useState([])
    const [jobRolesSkills, setJobRolesSkills] = useState({
        GeneralSkills: [],
        ProfessionalSkills: [],
        ProSuggestedSkills: [],
        GenSuggestedSkills: []
    })
    const [matchingJobRoleData, setMatchingJobRolesData] = useState([])
    const [checkboxGenStates, setCheckboxGenStates] = useState([])
    const [checkboxProStates, setCheckboxProStates] = useState([])
    const [showSelectGenSkillModal, setShowSelectGenSkillModal] = useState(false)
    const [selectSkill, setSelectSkill] = useState()
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const [proSugestSkills, setProSuggestSkills] = useState()
    const [genSugestSkills, setGenSuggestSkills] = useState()
    const [showPSAlert, setShowPSAlert] = useState(false)
    const [showGSAlert, setShowGSAlert] = useState(false)
    const [showProfSkillsOptions, setShowProfSkillsOptions] = useState(false)
    const [searchPQuery, setSearchPQuery] = useState('')
    const [searchGQuery, setSearchGQuery] = useState('')
    const [showGenSkillsOptions, setShowGenSkillsOptions] = useState(false)
    const [domainProfSkills, setDomainProfSkills] = useState()
    const [domainGenSkills, setDomainGenSkills] = useState()
    const [selectedSubdomains, setSelectedSubdomains] = useState([])
    const [fileName, setFileName] = useState('No file chosen');
    const [uploadingLoader, setUploadingLoader] = useState(false);
    const fileInputRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageSet, setImageSet] = useState(false);
    const [userInfo, setUserInfo] = useState([])
    const [gridValue, setGridValue] = useState()








    useEffect(() => {
        // Prompt confirmation when reload page is triggered
        window.onbeforeunload = () => {
            return 'Data will be lost'
        }

        // Unmount the window.onbeforeunload event
        return () => {
            window.onbeforeunload = null
        }
        const tempGridValue = (window.innerWidth > 600) ? 'notMobile' : 'mobile'
        setGridValue(tempGridValue)
    }, [])

    document.onkeydown = function (e) {
        if (e.key === 'r' && (e.ctrlKey || e.metaKey || e.shiftKey)) {
            e.preventDefault()
            return false
        }
    }

    const [getSkills, setGetSkills] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllSkills(authToken)
            setGetSkills(data)
        }
        fetchData()
    }, [authToken])

    const [showLoader, setShowLoader] = useState(true);
    // jobroles skills
    useEffect(() => {
        const fetchData = async () => {
            setShowLoader(true);
            try {
                const data = await fetchAllJobRolesSkills(authToken)
                setJobRolesSkills(data.data)
            } catch (error) {
                console.error("Error fetching skills.", error);
            } finally {
                setShowLoader(false);
            }
        };
        fetchData()
    }, [authToken])

    useEffect(() => {
        const domainValue = candidateData?.CareerProfile?.domain
        const matchingJobRole = jobRoles.find(jobRole => jobRole?.ID == domainValue)
        // setProSuggestSkills(matchingJobRole)
        setGenSuggestSkills(matchingJobRole)

        if (matchingJobRole) {
            const matchingDomain = matchingJobRole?.Domain
            const jobRoleWithMatchingDomain = jobRoles.filter(
                jobRole => jobRole?.Domain === matchingDomain
            )
            setMatchingJobRolesData(jobRoleWithMatchingDomain)
            setCheckboxGenStates(
                new Array(jobRoleWithMatchingDomain.length).fill(false)
            )
            setCheckboxProStates(
                new Array(jobRoleWithMatchingDomain.length).fill(false)
            )
            console.log('Matching jobRoles:', { jobRoleWithMatchingDomain, jobRoles })
            // const ProfSkillsbyDomain = [
            //     ...new Set(
            //         jobRoleWithMatchingDomain
            //             .map(jobRole => jobRole?.ProfessionalSkills || [])
            //             .flat()
            //     )
            // ].map(skill => ({value: skill, label: skill}));
            const ProfSkillsbyDomain = jobRoleWithMatchingDomain
                ?.map(item => {
                    return item.ProfessionalSkills?.map(skill => ({
                        value: skill,
                        label: skill,
                        subdomain: item.Subdomain
                    }))
                })
                .flat()
                .filter(itm => itm)
            const AllProfessionalSkills = jobRoles
                ?.map(item => {
                    return item.ProfessionalSkills?.map(skill => ({
                        value: skill,
                        label: skill,
                        subdomain: item.Subdomain
                    }))
                })
                .flat()
                .filter(itm => itm)
            // console.log("Prof Skills with subdomains:", ProfSkillsbyDomain);
            // console.log("All Professional Skills:", AllProfessionalSkills);
            const uniqueProfSkillsByDomain = [
                ...new Map(
                    ProfSkillsbyDomain.map(skill => [skill.value, skill]) // Use skill.value as the unique key
                ).values()
            ]
            const uniqueAllProfessionalSkills = [
                ...new Map(
                    AllProfessionalSkills.map(skill => [skill.value, skill])
                ).values()
            ]
            // console.log("Unique Prof Skills by Domain:", uniqueProfSkillsByDomain);
            // console.log("ALl Unique Prof Skills:", uniqueAllProfessionalSkills);
            // setDomainProfSkills(uniqueProfSkillsByDomain);
            setDomainProfSkills(uniqueAllProfessionalSkills);

            const TopProfSkillsbyDomain = jobRoleWithMatchingDomain
                ?.map(item => {
                    return item.SuggestProSkills?.map(skill => ({
                        value: skill,
                        label: skill,
                        subdomain: item.Subdomain
                    }))
                })
                .flat()
                .filter(itm => itm)
            // console.log("Top Suggested Prof Skills with subdomains:", TopProfSkillsbyDomain);
            const uniqueTopProfSkillsByDomain = [
                ...new Map(
                    TopProfSkillsbyDomain.map(skill => [skill.value, skill]) // Use skill.value as the unique key
                ).values()
            ]
            console.log("Unique Top Prof Skills by Domain:", uniqueTopProfSkillsByDomain);
            setProSuggestSkills(uniqueTopProfSkillsByDomain);

            const GenSkillsbyDomain = [
                ...new Set(
                    jobRoleWithMatchingDomain
                        .map(jobRole => jobRole?.GeneralSkills || [])
                        .flat()
                )
            ].map(skill => ({ value: skill, label: skill }))
            const AllGeneralSkills = [
                ...new Set(
                    jobRoles
                        .map(jobRole => jobRole?.GeneralSkills || [])
                        .flat()
                )
            ].map(skill => ({ value: skill, label: skill }))
            const uniqueAllGeneralSkills = [
                ...new Map(
                    AllGeneralSkills.map(skill => [skill.value, skill])
                ).values()
            ]
            // console.log("Unique Gen Skills by Domain:", GenSkillsbyDomain);
            // console.log("All Unique Gen Skills:", uniqueAllGeneralSkills);
            setDomainGenSkills(uniqueAllGeneralSkills)
        }
    }, [jobRoles, candidateData])

    const generalSkillOptions = jobRolesSkills?.GeneralSkills.map(skill => ({
        value: skill,
        label: skill
    }))

    const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills.map(
        skill => ({
            value: skill,
            label: skill
        })
    )
    const genSuggestedSkillOptions = genSugestSkills?.SuggestGenSkills?.map(
        skill => ({
            value: skill,
            label: skill
        })
    )

    const handleSelectedGeneralSkills = selectedOptions => {
        const selectGSkills = selectedOptions.map(option => option.value)
        setSelectGSkills(selectGSkills)
    }
    const handleSelectedProfessionalSkills = selectedOptions => {
        const selectPSkills = selectedOptions.map(option => option.value)
        setSelectPSkills(selectPSkills)
        console.log("Selected Skills:", selectPSkills);
    }

    const [hasError, setHasError] = useState({
        selectGSkills: false,
        selectPSkills: false
    })

    const showErrorMsg = e => {
        const showError = { ...hasError }
        console.log(showError)
        if (e === 'Select General Skills') {
            console.log(e)
        }
        if (e === 'Select Professional Skills') {
            console.log(e)
        }
    }

    useEffect(() => {
        const fetchCandidateByID = async () => {
            const data = await GetCandidateByID(ID, authToken)
            setCandidateData(data.data)
        }
        fetchCandidateByID()
    }, [ID, authToken])

    useEffect(() => {
        const fetchJobRoles = async () => {
            const data = await fetchAllJobRoles(authToken)
            setJobRoles(data.data)
        }
        fetchJobRoles()
    }, [authToken])

    // General Skills

    // const [checkboxGenStates, setCheckboxGenStates] = useState(Array(matchingJobRoleData.length).fill(false));
    const handleGenCheckboxChange = idx => {
        // Update the checked state for the checkbox at the given index
        setCheckboxGenStates(prevStates => {
            const newState = [...prevStates]
            newState[idx] = !newState[idx]
            return newState
        })
        if (!checkboxGenStates[idx]) {
            const seletedJobRoleGenSkills = matchingJobRoleData[idx]?.GeneralSkills
            // Add new skills to selectGSkills
            const updatedSkills = Array.from(
                new Set([...selectGSkills, ...seletedJobRoleGenSkills])
            )
            setSelectGSkills(updatedSkills)
        } else {
            const deselectedJobRoleGenSkills = matchingJobRoleData[idx]?.GeneralSkills
            // Remove deselected skills from selectGSkills
            const updatedSkills = selectGSkills.filter(
                skill => !deselectedJobRoleGenSkills.includes(skill)
            )
            setSelectGSkills(updatedSkills)
        }
    }

    const handleGenSkillSelect = skill => {
        const updatedSkills = Array.from(new Set([...selectGSkills, skill]))
        setSelectGSkills(updatedSkills)
    }

    // professional Skills

    // const [checkboxProStates, setCheckboxProStates] = useState(Array(matchingJobRoleData.length).fill(false));
    const handleProCheckboxChange = idx => {
        // Set the checked state of the checkbox
        setCheckboxProStates(prevStates => {
            const newStates = [...prevStates]
            newStates[idx] = !newStates[idx]
            return newStates
        })
        if (!checkboxProStates[idx]) {
            const selectedJobRoleProSkills =
                matchingJobRoleData[idx]?.ProfessionalSkills
            const updatedSkills = Array.from(
                new Set([...selectPSkills, ...selectedJobRoleProSkills])
            )
            setSelectPSkills(updatedSkills)
        } else {
            const deselectedJobRoleProSkills =
                matchingJobRoleData[idx]?.ProfessionalSkills
            const updatedSkills = selectPSkills.filter(
                skill => !deselectedJobRoleProSkills.includes(skill)
            )
            setSelectPSkills(updatedSkills)
        }
    }

    // const handleProSkillSelect = skill => {
    //     console.log("Skill prop: ", skill);
    //     // const selectedSkill = skill;
    //     // const selectedSkillString = selectedSkill.join(', ');


    //     // Ensure `skill` is an array or convert it to an array if it's a single object
    //     const skillArray = Array.isArray(skill) ? skill : [skill];
    //     // Convert incoming skill array to a string
    //     const incomingSkillString = skillArray.map(s => s.value).join(', ');
    //     // Append incoming skills to existing selectedSkillString
    //     const selectedSkillString = [incomingSkillString]
    //         .concat(selectPSkills.map(s => s.value).join(', '))
    //         .filter(Boolean)
    //         .join(', ');

    //     console.log("Incoming skill:", skill.map(skill => skill?.value), selectedSkill);
    //     console.log("Selected Skill String:", selectedSkillString);
    //     const { subdomain } =
    //         domainProfSkills?.find(ps => ps.value === skill?.value) ||
    //         proSugestSkills?.find(ps => ps.value === skill?.value) ||
    //         {};
    //     const updatedSkills = Array.from(new Set([...selectPSkills, ...selectedSkill]))
    //     setSelectPSkills(updatedSkills)
    //     setSearchPQuery(skill?.value)
    //     setSelectedSubdomains(previousSubdomains => {
    //         const updatedSubdomains = Array.from(
    //             new Set([...previousSubdomains, subdomain])
    //         )
    //         console.log('Selected Subdomains:', updatedSubdomains)
    //         return updatedSubdomains
    //     })
    //     console.log('Before update:', selectPSkills)
    //     console.log('Adding skill:', skill?.value)
    //     console.log('Adding skill subdomains:', selectedSubdomains)
    //     console.log('After update:', updatedSkills)
    // }





    // 1





    // const handleProSkillSelect = (skill) => {
    //     console.log("Selected skills: ", skill);

    //     // Ensure `skill` is an array
    //     if (!Array.isArray(skill)) {
    //         skill = skill ? [skill] : []; // Convert single object to array or default to empty array
    //     }

    //     // Normalize input to always be an array of strings
    //     const normalizedSkills = skill.map(skill =>
    //         typeof skill === "string" ? skill : skill.value
    //     );

    //     // Convert skills to a string for display/logging
    //     const selectedSkillString = normalizedSkills.join(", ");
    //     console.log("Selected Skill String:", selectedSkillString);

    //     // Update the state with the normalized skills
    //     const updatedSkills = [...selectPSkills, ...normalizedSkills]; // Add the new selected skills

    //     // Ensure `updatedSkills` is unique
    //     const uniqueUpdatedSkills = Array.from(new Set(updatedSkills));

    //     // Update state
    //     setSelectPSkills(uniqueUpdatedSkills);

    //     console.log("Updated Skills:", uniqueUpdatedSkills);

    //     // Example: Render selectedSkillString or updatedSkills safely
    //     return (
    //         <div>
    //             <p>Selected Skills: {selectedSkillString}</p>
    //             {uniqueUpdatedSkills.map((skill, index) => (
    //                 <div key={index}>{skill}</div> // Directly render string skill here
    //             ))}
    //         </div>
    //     );
    // };



    const verifyGenCheckboxStates = () => {
        const updatedCheckboxGenStates = checkboxGenStates.map((checked, idx) => {
            const jobRoleSkills = matchingJobRoleData[idx]?.GeneralSkills || []
            const allSkillsPresent = jobRoleSkills.every(skill =>
                selectGSkills.includes(skill)
            )
            return allSkillsPresent
        })
        setCheckboxGenStates(updatedCheckboxGenStates)
    }

    const verifyProCheckboxStates = () => {
        const updatedCheckboxProStates = checkboxProStates.map((checked, idx) => {
            const jobRoleSkills = matchingJobRoleData[idx]?.ProfessionalSkills || []
            const allSkillsPresent = jobRoleSkills.every(skill =>
                selectPSkills.includes(skill)
            )
            return allSkillsPresent
        })
        setCheckboxProStates(updatedCheckboxProStates)
    }

    useEffect(() => {
        verifyGenCheckboxStates()
    }, [selectGSkills, matchingJobRoleData])

    useEffect(() => {
        verifyProCheckboxStates()
    }, [selectPSkills, matchingJobRoleData])

    const handleRemoveGenSkill = skill => {
        const updatedSkills = selectGSkills.filter(allSkills => allSkills !== skill)
        setSelectGSkills(updatedSkills)
    }

    const handleRemoveProSkill = skill => {
        const updatedSkills = selectPSkills.filter(allSkills => allSkills !== skill)
        setSelectPSkills(updatedSkills)
        const { subdomain } =
            domainProfSkills?.find(ps => ps.value === skill) ||
            proSugestSkills?.find(ps => ps.value === skill) ||
            {};
        setSelectedSubdomains(previousSubdomains => {
            const remainingSkillsInSubdomain = domainProfSkills
                ?.filter((ps) => ps.subdomain === subdomain && updatedSkills.includes(ps.value));
            if (!remainingSkillsInSubdomain.length) {
                return previousSubdomains.filter((sd) => sd !== subdomain);
            }
            return previousSubdomains;
        })
        console.log("Remaining subdomains:", selectedSubdomains);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            uploadRefImage(file)
        }
        console.log("File Name:", fileName, file.name);
    };

    // const uploadRefImage = filePath => {
    //     setFileUploaded(true)
    //     const formData = new FormData()
    //     formData.append('inputFile', filePath)
    //     axios
    //         .post('https://api.allpos.in/applications/upload/scopehai', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         })
    //         .then(res => {
    //             console.log(res.data.data)
    //             setUploadedFile(res.data.data)
    //             setFileUploaded(false)
    //         })
    //         .catch(err => {
    //             console.log(err)
    //             setFileUploaded(false)
    //             console.error('Error uploading file:', err)
    //         })
    //     console.log("Uploaded file:", uploadedFile);
    // }

    const handleFileUpload = async (e) => {
        setUploadingLoader(true)
        console.log('handleImageChange fuc triggerd !!!!!');

        const file = e.target.files[0];
        console.log('Selected file:', file);
        if (file) {
            // setPreviewImage(URL.createObjectURL(file));
            try {
                const imageUrl = await uploadRefImage(file); // Wait for the image URL
                console.log('imageUrl: ', imageUrl);


                console.log('Call handleSetImage with the image URL');
                await handleSetImage(uploadedFile); // Call handleSetImage with the image URL
            } catch (error) {
                console.error('Error handling image change:', error);
            }
        }
    };

    const uploadRefImage = async (filePath) => {
        console.log(filePath)
        console.log('upload ref fuc triggerd !!!!!');

        setFileUploaded(true);
        const formData = new FormData();
        console.log('FormData:', formData);
        formData.append('inputFile', filePath);
        try {
            const res = await axios.post('https://api.allpos.in/applications/upload/test', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            console.log('getting consoled  !!!!!');
            console.log('uploaded image data : ', res.data.data);

            let certificates = uploadedFile
            certificates.push(res.data.data)
            setUploadedFile(certificates)
            setFileUploaded(false)
            setUploadingLoader(false)
            return res.data.data; // Return the uploaded image URL
        } catch (err) {
            console.error('Error uploading file:', err);
            setFileUploaded(false);
            setUploadingLoader(false)
            throw err;
        }
    };

    const handleSetImage = async (imageUrl) => {
        console.log('handel set image fuc triggerd !!!!!');
        console.log('image URL: ', imageUrl);

        const imageUrlObject = {
            image: imageUrl
        };
        console.log('before triggering the image upload to DB: ', imageUrlObject);

        await postUserCertificates(ID, imageUrlObject, candidateAuth);

        setUserInfo(prevUserInfo => ({
            ...prevUserInfo,
            Image: imageUrl // Use the provided image URL
        }));

        setImageSet(true);
        setPreviewImage(null);

        fileInputRef.current.value = '';
    };

    //to privent user to go back while regitration is in progress
    const navigate = useNavigate()

    useEffect(() => {
        const handlePopState = event => {
            // Prevent the default back action
            event.preventDefault()
            // Redirect the user to the same page or another page
            navigate('/complete-profile?step=step2') // Adjust the path accordingly
        }

        window.history.pushState(null, null, window.location.pathname) // Prevent back initially
        window.addEventListener('popstate', handlePopState)

        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [navigate])

    function handleGoBack() {
        const updateStep = {
            stepNo: 1,
            completeProfileStep: ID
        }
        updateRegistrationStep(updateStep)
    }

    // filtered out duplicate domains
    const uniqueDomain = Array.from(
        new Set(
            jobRoles.filter(role => role.AppVisibility).map(role => role.Domain)
        )
    ).map(domain =>
        jobRoles.find(role => role.Domain === domain && role.AppVisibility)
    )

    const UniqueDomain = [
        { ID: 1, skill: 'java' },
        { ID: 2, skill: 'paython' },
        { ID: 3, skill: 'react' },
        { ID: 4, skill: 'node' },
        { ID: 5, skill: 'postgressql' },
        { ID: 6, skill: 'javaScript' }
    ]

    // Debounce utility function
    const debounce = (func, delay) => {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => func(...args), delay)
        }
    }

    // Debounced function to update options based on inputValue
    const updateOptions = debounce(value => {
        const filteredOptions = UniqueDomain.filter(role =>
            role.skill.toLowerCase().includes(value.toLowerCase())
        ).map(role => ({ value: role.ID, label: role.skill }))
        setOptions(filteredOptions)
    }, 1000)

    const searchProfSkillsRef = useRef()
    const handleProfSkillsFocus = () => setShowProfSkillsOptions(true)
    const handleProfSkillsBlur = e => {
        setTimeout(() => {
            if (!searchProfSkillsRef?.current.contains(e.relatedTarget)) {
                setShowProfSkillsOptions(false)
            }
        }, 1000)
    }
    const handleProfSearchChange = e => {
        setSearchPQuery(e.target.value)
    }
    const filteredProfSkillOptions = domainProfSkills
        ?.filter(
            skill =>
                skill.label &&
                skill.value &&
                skill.label.trim() !== '' &&
                skill.value.trim() !== '' &&
                (searchPQuery
                    ? skill.value.toLowerCase().includes(searchPQuery)
                    : true)
        )
        .sort((a, b) => {
            const indexA = selectedSubdomains.indexOf(a.subdomain)
            const indexB = selectedSubdomains.indexOf(b.subdomain)

            // If both skills' subdomains are in selectSubdomains, sort by their order in selectSubdomains
            if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB
            }
            // If only one skill's subdomain is in selectSubdomains, prioritize it
            if (indexA !== -1) return -1
            if (indexB !== -1) return 1
            // If neither skill's subdomain is in selectSubdomains, maintain original order
            return 0
        })
    // console.log('Filtered Prof Skills:', filteredProfSkillOptions)
    const filteredTopProfSkillOptions = proSugestSkills
        ?.filter(
            skill =>
                skill.label &&
                skill.value &&
                skill.label.trim() !== '' &&
                skill.value.trim() !== '' &&
                (searchPQuery
                    ? skill.value.toLowerCase().includes(searchPQuery)
                    : true)
        )

    const excludedProfSkills = selectPSkills || [];
    const visibleAllProfSkillsOptions = filteredProfSkillOptions?.filter(
        skill => !excludedProfSkills.includes(skill.value)
    )
    const visibleSuggestedProfSkillsOptions = proSugestSkills?.filter(
        skill => !excludedProfSkills.includes(skill.value)
    )
    console.log(visibleSuggestedProfSkillsOptions)
    const searchGenSkillsRef = useRef()
    const handleGenSkillsFocus = () => setShowGenSkillsOptions(true)
    const handleGenSkillsBlur = e => {
        setTimeout(() => {
            if (!searchGenSkillsRef.current.contains(e.relatedTarget)) {
                setShowGenSkillsOptions(false)
            }
        }, 2000)
    }
    const handleGenSearchChange = e => {
        setSearchGQuery(e.target.value)
    }
    const filteredGenSkillOptions = domainGenSkills?.filter(
        skill =>
            skill.label &&
            skill.value &&
            skill.label.trim() !== '' &&
            skill.value.trim() !== '' &&
            (searchGQuery
                ? skill.label.toLowerCase().includes(searchGQuery.toLowerCase())
                : true)
    )
    const excludedGenSkills = selectGSkills
    const visibleAllGenSkillsOptions = filteredGenSkillOptions?.filter(
        skill => !excludedGenSkills.includes(skill.value)
    )
    const visibleSuggestedGenSkillsOptions = genSuggestedSkillOptions?.filter(
        skill => !excludedGenSkills.includes(skill.value)
    )
    const visibleAllSkillsOptions = [
        ...(visibleAllProfSkillsOptions || []),
        ...(visibleAllGenSkillsOptions || [])
    ];
    // console.log("All Visible skill options:", visibleAllSkillsOptions);

    // Call debounced function on inputValue change
    useEffect(() => {
        updateOptions(inputValue)
    }, [inputValue])

    console.log('grid value : ', gridValue);

    const customSkillsStyles = {
        menu: (provided) => ({
            ...provided,
            padding: 10,
        }),
        menuList: (provided) => ({
            ...provided,
            display: "grid",
            gridTemplateColumns: (window.innerWidth < 600) ? '1fr' : '1fr 1fr', // Two-column layout
            gap: 10,
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: "pointer",
            backgroundColor: state.isSelected ? "#e0f7fa" : "#fff",
            border: state.isFocused ? "1px solid #00796b" : "1px solid #ccc",
            borderRadius: "10px",
            padding: "10px",
            textAlign: "center",
            boxShadow: state.isFocused ? "0 2px 5px rgba(0, 0, 0, 0.2)" : "none",
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: "#e0e0e0",
            borderRadius: "15px",
            padding: "0 5px",
            margin: "2px",
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: "#000",
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: "#ff5252",
            cursor: "pointer",
        }),
    };

    // function submitForm(e) {
    //     e.preventDefault()
    //     const form = e.currentTarget
    //     if (form.checkValidity() === false) {
    //         e.stopPropagation()
    //     }
    //     setShowPSAlert(false)
    //     setShowGSAlert(false)
    //     const newStep2RegData = {
    //         selectGSkills: selectGSkills,
    //         selectPSkills: selectPSkills,
    //         uploadedFile: uploadedFile,
    //         stepNo: 2,
    //         completeProfileStep: completeProfileStep,
    //         selectNotification: selectNotification
    //     }
    //     submitFormStep2(newStep2RegData)
    //     setValidated(true)
    // }



    // const handleProSkillSelect = (selectedOptions) => {
    //     console.log("Selected options: ", selectedOptions);
    //     console.log("Type of selected options: ", typeof selectedOptions);

    //     let normalizedSkills;

    //     // Check if selectedOptions is a string
    //     if (typeof selectedOptions === "string") {
    //         // Convert string to an array
    //         const normalizedS = [selectedOptions];
    //         normalizedSkills = normalizedS.map(option => { return option });


    //     } else if (Array.isArray(selectedOptions)) {
    //         // Process array of options
    //         normalizedSkills = selectedOptions.map(option => {
    //             console.log("Option being processed:", option);
    //             return typeof option === "string" ? option : option.value;
    //         });
    //     } else {
    //         // Default to an empty array if no valid options are provided
    //         normalizedSkills = [];
    //     }

    //     // Update state with the normalized skills
    //     setSelectPSkills(normalizedSkills);

    //     console.log("Updated Skills:", normalizedSkills);
    // };






    /////////////////////////////////////////////////////////////////////////////////////


    // const handleProSkillSelect = (selectedOptions) => {
    //     console.log("Selected options (raw): ", selectedOptions);
    //     console.log("Type of selected options: ", typeof selectedOptions);

    //     let normalizedSkills = [];

    //     // Normalize selectedOptions into an array of objects with key-value pairs
    //     if (typeof selectedOptions === "string") {
    //         // Single string selected, convert it into an object
    //         normalizedSkills = [{
    //             value: selectedOptions,
    //             label: selectedOptions // Assuming the label is the same as the value
    //         }];
    //     } else if (Array.isArray(selectedOptions)) {
    //         // If it's an array, ensure each option is in the key-value format
    //         normalizedSkills = selectedOptions.map(option => {
    //             if (typeof option === "string") {
    //                 // If it's a string, create the object with the same value and label
    //                 return { value: option, label: option };
    //             }
    //             // Otherwise, return the option as is (which should already be an object)
    //             return option;
    //         });
    //     } else if (typeof selectedOptions === "object" && selectedOptions !== null) {
    //         // Handle case when selectedOption is a single object
    //         normalizedSkills = [{
    //             value: selectedOptions.value,
    //             label: selectedOptions.label || selectedOptions.value // Fallback to value if no label is provided
    //         }];
    //     }

    //     console.log("Normalized Skills:", normalizedSkills);

    //     // Create a copy of the current selected skills
    //     let updatedSkills = [...selectPSkills];

    //     normalizedSkills.forEach(skill => {
    //         const index = updatedSkills.findIndex(existingSkill => existingSkill.value === skill.value);

    //         if (index !== -1) {
    //             // If the skill is already in the array, remove it (deselect)
    //             updatedSkills.splice(index, 1);
    //             console.log('Skill removed:', skill);
    //         } else {
    //             // If the skill is not in the array, add it (select)
    //             updatedSkills.push(skill);
    //             console.log('Skill added:', skill);
    //         }
    //     });

    //     // Update the state after processing all changes
    //     setSelectPSkills(updatedSkills);

    //     console.log("Updated Skills:", updatedSkills);
    // };











    // Handle the selection/deselection of skills


    /////////////////////////////////////////////////////////


    // const handleProSkillSelect = (selectedOptions) => {
    //     // selectedOptions is an array of objects
    //     const selectedSkills = selectedOptions ? selectedOptions.map(option => option.value) : [];
    //     console.log('selected skills: ', selectedSkills);

    //     setSelectPSkills(selectedSkills);  // Store only the values in the state
    // };




    const handleProSkillSelect = (selectedOptions) => {
        console.log('selectedOption: ', selectedOptions);

        // Handle deselection (when all options are removed)
        if (!selectedOptions) {
            setSelectPSkills([]);
            return;
        }

        // Handle both single selection from suggested skills and multi-select from dropdown
        if (Array.isArray(selectedOptions)) {
            // Handle multi-select from dropdown
            const skills = selectedOptions.map(option => option.value);
            setSelectPSkills(skills);
        }
        else {
            // Handle single selection from suggested skills
            const newSkill = selectedOptions.value;

            setSelectPSkills(prevSkills => {
                // If skill already exists, don't add it again
                if (prevSkills.includes(newSkill)) {
                    return prevSkills;
                }
                return [...prevSkills, newSkill];
            });
        }
    };





    // const handleProSkillSelect = (selectedOptions) => {
    //     // Initialize separate arrays for string and key-value object handling
    //     let stringSkills = [];
    //     let objectSkills = [];

    //     console.log('selected Skill: ', selectedOptions);
    //     console.log('selected Skill type: ', typeof selectedOptions);

    //     // Check if selectedOptions is a string and handle it
    //     if (!Array.isArray(selectedOptions)) {
    //         const skill = selectedOptions; // Ensure selectedOptions is a valid object (or string)
    //         console.log('skills: ', skill);

    //         // Create an array with the skill if it's not already in array format
    //         const ary = [skill];
    //         console.log('array:', ary);

    //         // Initialize stringSkills if it's undefined or null
    //         if (!stringSkills) {
    //             stringSkills = [];
    //         }

    //         // Push the value into the stringSkills array (assuming skill has a 'value' property)
    //         stringSkills.push(skill?.value); // Push the 'value' of the skill into the array
    //         console.log('stringSkills', stringSkills); // Log the updated array
    //     }

    //     // Check if selectedOptions is an array of objects and handle it
    //     if (Array.isArray(selectedOptions)) {
    //         objectSkills = selectedOptions.map(option => option.value); // Extract values from objects
    //         console.log('objectSkills', objectSkills);
    //     }

    //     // Merge both arrays: stringSkills and objectSkills
    //     const mergedSkills = [...stringSkills, ...objectSkills];

    //     // Update the state with the merged skills
    //     setSelectPSkills(mergedSkills);

    //     console.log("Updated Skills:", mergedSkills); // Optional: Check the merged result
    // };





    // const handleProSkillSelect = (selectedOptions) => {
    //     console.log('selected Skill: ', selectedOptions);
    //     console.log('selected Skill: ', typeof selectedOptions);

    //     // Initialize separate arrays for string and key-value object handling
    //     let stringSkills = selectSkill;
    //     let objectSkills = [];

    //     // Check if selectedOptions is a string and handle it
    //     if (!Array.isArray(selectedOptions)) {
    //         const skill = selectedOptions; // Ensure selectedOptions is a valid object (or string)
    //         console.log('skills: ', skill);

    //         // Create an array with the skill if it's not already in array format
    //         const ary = [skill];
    //         console.log('array:', ary);

    //         // Push the value into the stringSkills array (assuming skill has a 'value' property)
    //         stringSkills.push(skill?.value); // Push the 'value' of the skill into the array
    //         console.log('stringSkills', stringSkills); // Log the updated array
    //     }

    //     // Check if selectedOptions is an array of objects and handle it
    //     if (Array.isArray(selectedOptions)) {
    //         objectSkills = selectedOptions.map(option => option.value); // Extract values from objects
    //         console.log('objectSkills', objectSkills);
    //     }

    //     // Merge both arrays: stringSkills and objectSkills
    //     // const mergedSkills = [...stringSkills, ...objectSkills];

    //     // Update the state with the merged skills
    //     setSelectPSkills(stringSkills);

    //     // console.log("Updated Skills:", mergedSkills); // Optional: Check the merged result
    // };














































    // const handleProSkillSelect = (selectedOptions) => {
    //     console.log('seleected option: ', selectedOptions);

    //     let selectedSkills;

    //     if (typeof selectedOptions === 'string') {
    //         let selectedSkills = [...selectPSkills]; // Spread the current state into a new array

    //         if (Array.isArray(selectedOptions)) {
    //             // If selectedOptions is a string, push it directly to the array
    //             selectedSkills.push(selectedOptions);
    //         }
    //         else {
    //             console.log('below skill pills are not in a array!!!');
    //         }
    //     } else if (Array.isArray(selectedOptions)) {
    //         // If it's an array, map it to the correct format
    //         selectedSkills.push(...selectedOptions.map(option => option.value));
    //     } else {
    //         // Default to an empty array if no valid options are provided
    //         selectedSkills = [];
    //     }

    //     setSelectPSkills(selectedSkills);  // Store only the values in the state

    //     console.log("Updated Skills:", selectedSkills);  // Optional: to check the updated skills
    // };
    //////////



    //////////////////////////////////////////////////////////////
    // const handleProSkillSelect = (selectedOptions) => {
    //     console.log("Selected options: ", selectedOptions);

    //     // Normalize the selected options into an array of strings
    //     const normalizedSkills = selectedOptions
    //         ? selectedOptions.map(option => (typeof option === "string" ? option : option.value))
    //         : []; // Default to an empty array if no options are selected

    //     // Update state with the normalized skills
    //     setSelectPSkills(normalizedSkills);

    //     console.log("Updated Skills:", normalizedSkills);
    // };






    const handleSubmit = (e) => {
        e.preventDefault();

        const newStep2RegData = {
            selectGSkills,
            selectPSkills,
            uploadedFile,
            stepNo: 2,
            completeProfileStep,
            selectNotification,
        };

        console.log("Submitting data from child:", newStep2RegData);

        // Send data to the parent
        if (onSendData) {
            onSendData(newStep2RegData);
        }
    };

    const handleDeleteCertificate = (index) => {
        let certificates = removeItemAtIndex(index)
        setUploadedFile(certificates)
    }

    const removeItemAtIndex = (index) => {
        if (index >= 0 && index < uploadedFile.length) {
            return [...uploadedFile.slice(0, index), ...uploadedFile.slice(index + 1)];
        }
        return uploadedFile; // Return the original array if the index is invalid
    };







    console.log('uploadedFile array :', uploadedFile);




    return (
        <section className='bg-white'>
            <div style={{ height: '90vh' }}>
                <div className='h-100'>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className='h-100 w-100'>
                            <Col md={8} sm={12} className='skills-col-div h-100 '>
                                <div>
                                    <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                </div>
                                <div className='d-flex gap-2 mt-2 ms-2'>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                    <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                </div>
                                <span class="ms-2" style={{ fontSize: '14px' }}>5 of 6</span>
                                <div className="d-flex flex-column">
                                    <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Skills & Interests</h2>
                                    <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>Select upto 10 skills from the following.</lable>
                                </div>

                                <div className="ms-2 d-flex flex-column justify-content-center align-items-start p-1" style={{ marginTop: '2rem', backgroundColor: '#f5f5f5', borderRadius: '14px' }}>
                                    <lable className="mb-2" style={{ fontWeight: '500', color: 'black' }}>10 of 10 Remaining</lable>
                                    <Form.Group className="w-100 boxed mb-2" ref={searchProfSkillsRef}>
                                        {/* <InputGroup className="boxed position-relative flex-nowrap">
                                             <InputGroup.Text className='border-none bg-white'>
                                                <span className="fa fa-search"></span>
                                            </InputGroup.Text>
                                            <Select
                                                isMulti
                                                id="profSkillsSearch"
                                                options={visibleAllSkillsOptions}
                                                value={selectPSkills.map(skill => ({ value: skill, label: skill }))}
                                                styles={customSkillsStyles}
                                                placeholder="Search or select from the list below"
                                                className="basic-multi-select border-none"
                                                classNamePrefix="select"
                                                onChange={handleProSkillSelect}
                                                onFocus={handleProfSkillsFocus}
                                                onBlur={handleProfSkillsBlur}
                                                components={{
                                                    DropdownIndicator: () => null,
                                                    IndicatorSeparator: () => null,
                                                }}
                                            />

                                        </InputGroup> */}




                                        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}



                                        <InputGroup className="boxed position-relative flex-nowrap">
                                            <InputGroup.Text className="border-none bg-white">
                                                <span className="fa fa-search"></span>
                                            </InputGroup.Text>
                                            <Select
                                                isMulti
                                                id="profSkillsSearch"
                                                options={visibleAllSkillsOptions}
                                                value={selectPSkills.map(skill => ({ value: skill, label: skill }))} // Map skills to the format expected by Select
                                                styles={customSkillsStyles}
                                                placeholder="Search or select from the list below"
                                                className="basic-multi-select border-none"
                                                classNamePrefix="select"
                                                onChange={handleProSkillSelect} // Handle select and deselect
                                                onFocus={() => console.log('Focus event')} // Optional: handle focus
                                                onBlur={() => console.log('Blur event')} // Optional: handle blur
                                                components={{
                                                    DropdownIndicator: () => null,
                                                    IndicatorSeparator: () => null,
                                                }}
                                            />
                                        </InputGroup>



                                        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                                        {/* {showProfSkillsOptions && (
                                    <div className="skill-grid">
                                        {visibleAllSkillsOptions.map((skill, skillIdx) => (
                                        <div
                                            key={skillIdx}
                                            className="new-chip-ui ps-05 ms-05 mb-05"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <span
                                            className="d-flex justify-content-center align-items-start"
                                            onClick={() => handleProSkillSelect(skill.value)}
                                            >
                                            <span className="chip-label">{skill.label}</span>
                                            </span>
                                            {selectPSkills.includes(skill.value) ? (
                                            <span
                                                className="pe-1"
                                                onClick={() => handleRemoveProSkill(skill.value)}
                                            >
                                                <RxCross2 />
                                            </span>
                                            ) : (
                                            <span className="pe-3"></span>
                                            )}
                                        </div>
                                        ))}
                                    </div>
                                    )} */}
                                    </Form.Group>
                                    {/* {!selectPSkills ? (
                                        <></>
                                    ) : (
                                        <>
                                            <div
                                                className="skills-container my-1"
                                            >
                                                <div className="skills-container-inner">
                                                    {selectPSkills.map((skill, idx) => (
                                                        <div
                                                            key={`selectedProfessionalSkills-${idx}`}
                                                            className="new-chip-ui ms-05 mb-05 px-2"
                                                        >
                                                            <span className="chip-label">
                                                                {skill}{' '}
                                                                <span
                                                                    onClick={() => handleRemoveProSkill(skill)}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <X className="new-ui-cross" />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )} */}
                                </div>
                                <div className="container-fluid mt-2">
                                    <lable className='mt-2' style={{ fontWeight: '500', color: 'black' }}>Upload certification for the professional skill mentioned (If any)</lable>
                                    <div className="container-fluid p-0 ">
                                        <label
                                            htmlFor="fileInput"
                                            className="container-fluid px-2"
                                            style={{ height: '40px', padding: '6px 0px', border: '1px solid #E5E5E5', borderRadius: '8px', fontWeight: '500', display: 'inline-block', backgroundColor: 'white', fontSize: '16px', cursor: 'pointer', borderRadius: '8px', textAlign: 'start' }}
                                        >
                                            Choose file <span className='ms-05 ps-05' style={{ color: 'black', fontWeight: '300', borderLeft: '1px solid #e5e5e5' }}>{fileName}</span>
                                        </label>
                                        <input
                                            id="fileInput"
                                            type="file"
                                            accept='image/*,application/pdf'
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                        />
                                        <span class="fs-6 ps-2 text-danger">
                                            {uploadingLoader ? 'Uploading....' : ''}
                                        </span>
                                        <div class="d-flex">
                                            {uploadedFile?.map((v, k) => {
                                                return (
                                                    <div>
                                                        <div style={{ position: 'relative', width: 'fit-content' }}>
                                                            <img src={v} class="border m-1 mt-2" width={'110px'} key={k} />
                                                            <div class="d-flex align-items-center justify-content-center cursor-pointer" style={{ position: 'absolute', background: 'rgba(51, 51, 51, 0.08)', borderRadius: '100px', top: 5, right: 0, height: '20px', width: '20px' }} onClick={() => { handleDeleteCertificate(k) }}>
                                                                <X size={16} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="container-fluid mt-2" style={{ overflowY: 'scroll', maxHeight: '12rem' }}>
                                    <div>
                                        <lable className="mb-2" style={{ fontWeight: '500', color: 'black' }}>Select Professional Skills</lable>
                                    </div>
                                    <div className="mb-5">
                                        {visibleSuggestedProfSkillsOptions?.map((skill, skillIdx) => (
                                            <div
                                                key={skillIdx}
                                                className="new-chip-ui bg-white mb-1"
                                                style={{ cursor: 'pointer', border: '2px solid #E5E5E5' }}
                                            >
                                                <span
                                                    className="d-flex justify-content-center align-items-start"
                                                    onClick={() => handleProSkillSelect(skill)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <span className="chip-label">{skill.label}</span>
                                                </span>
                                                {selectPSkills.includes(skill.value) ? (
                                                    <span
                                                        className="pe-1"
                                                        onClick={() => handleRemoveProSkill(skill.value)}
                                                    >
                                                        <RxCross2 />
                                                    </span>
                                                ) : (
                                                    <span className="pe-3"></span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        <lable className="mb-2" style={{ fontWeight: '500', color: 'black' }}>Select General Skills</lable>
                                    </div>
                                    <div className="mb-5">
                                        {visibleAllGenSkillsOptions?.map((skill, skillIdx) => (
                                            <div
                                                key={skillIdx}
                                                className="new-chip-ui bg-white mb-1"
                                                style={{ cursor: 'pointer', border: '1px solid #E5E5E5' }}

                                            >
                                                <span
                                                    className="d-flex justify-content-center align-items-start"
                                                    onClick={() => handleProSkillSelect(skill)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <span className="chip-label">{skill.label}</span>
                                                </span>
                                                {selectPSkills.includes(skill.value) ? (
                                                    <span
                                                        className="pe-1"
                                                        onClick={() => handleRemoveProSkill(skill.value)}
                                                    >
                                                        <RxCross2 />
                                                    </span>
                                                ) : (
                                                    <span className="pe-3"></span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="ms-2 mt-3 d-flex justify-content-between">
                                    <div>
                                        <button type="button" onClick={handleGoBack} className="btn btn-outline-primary" style={{ height: '48px', fontSize: '20px' }}><ArrowLeft className="me-1" style={{ height: '15px', width: '15px' }} /> Back <span className="ms-1"></span></button>
                                    </div>
                                    <div>
                                        <Button
                                            className="btn"
                                            style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}
                                            disabled={fileUploaded}
                                            type='submit'
                                        >
                                            Next
                                            <span className="ms-1"><ArrowRight style={{ height: '15px', width: '15px' }} /></span>
                                        </Button>
                                    </div>

                                </div>
                            </Col>
                            <Col md={4} sm={12} className='graph-analysis-didi1 p-2'>
                                <SideValiDidi />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </section >
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, submitFormStep1, submitFormStep2, candidateAuth
    } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        submitFormStep1,
        submitFormStep2,
        candidateAuth
    }
}

const mapDispatchToProps = {
    updateRegistrationStep
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSignup5)