import { React, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import { Row, Col, Badge, Button, Form, InputGroup } from 'react-bootstrap'
import { setXpLevel, setToken, updateRegistrationStep } from '../../../features/auth/authSlice';
import { fetchAllEduDegrees, fetchAllEduLevels, GetCandidateByID, fetchAllJobRoles, GetAllColleges } from '../../../features/constants/apis';
import Logo from '../../images/ScopeHaiLogo-two-color.png';
import { ArrowRight, User, GraduationCap, BriefcaseBusiness, Check, X, ArrowLeft } from 'lucide-react';
import SideValiDidi from '../SideValiDidi';
import { useNavigate } from 'react-router-dom';


const RegFormStep1 = ({ newUiCandidateEducationStep, changeForm, xpLevel, submitFormStep1, completeProfileStep, updateRegistrationStep, authToken1, ID }) => {

    const navigate = useNavigate();
    useEffect(() => {
        const performanceNavigation = performance.getEntriesByType("navigation")[0];
        if (performanceNavigation.type === "reload") {
            navigate('/');
        }
    }, [navigate]);


    console.log('newUiCandidateEducationStep:', newUiCandidateEducationStep);
    console.log('authToken1:', authToken1);
    console.log('ID in reg from step 1:', ID);

    const [validated, setValidated] = useState(false);
    const [selectEduLevels, setSelectEduLevels] = useState(null);
    const [selectEduDegrees, setSelectEduDegrees] = useState(null);
    const [selectXp, setSelectXp] = useState(null);
    const [selectProfile, setSelectProfile] = useState(null);
    const [selectIncome, setSelectIncome] = useState(null);
    const [selectDomain, setSelectDomain] = useState(null);
    const [jobDomain, setJobDomain] = useState(null);
    const [currentJob, setCurrentJob] = useState(null);
    const [selectedCollege, setSelectedCollege] = useState(null);
    const [getEduLevels, setGetEduLevels] = useState([]);
    const [getColleges, setGetColleges] = useState([]);
    const [getEduDegrees, setGetEduDegrees] = useState([]);
    const [candidateData, setCandidateData] = useState([]);

    console.log('selected College: ', selectedCollege)



    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllEduLevels(authToken1);
            setGetEduLevels(data);
        };
        fetchData();
    }, [ID]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await GetAllColleges(authToken1);
            setGetColleges(data?.colleges);
        };
        fetchData();
    }, [ID, authToken1]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllEduDegrees(authToken1);
            setGetEduDegrees(data);
        };
        fetchData();
    }, [ID]);


    useEffect(() => {
        console.log('useeffect is running for get by id');

        const fetchData = async () => {
            const data = await GetCandidateByID(ID, authToken1);
            setCandidateData(data.data);
            console.log(data.data, ID);
            console.log("Candidate Data:", data.data.ExperienceLevel);
            setSelectEduLevels(data.data.CareerProfile.education || '');
            setSelectEduDegrees(data.data.CareerProfile.degree || '');
            setSelectXp(data.data.CareerProfile.experience || '');
            setSelectIncome(data.data.CareerProfile.incomeCTC || '');
            setSelectDomain(data.data.CareerProfile.domain || '');
        };
        fetchData();
    }, [ID]);

    console.log(xpLevel, ID)

    const eduLevelOptions = getEduLevels.map(level => ({
        value: level.Level,
        label: level.Level,
    }));
    console.log('getEduLevels: ', getEduLevels)
    console.log('eduLevelOptions: ', eduLevelOptions)

    const handleSelectedEduLevels = (selectedOptions) => {
        console.log(selectedOptions)
        const selectEduLevels = selectedOptions;
        setSelectEduLevels(selectEduLevels);
    };

    const eduDegreeOptions = getEduDegrees.map(level => ({
        value: level.Education,
        label: level.Education,
    }));

    const handleSelectedEduDegrees = (selectedOptions) => {
        const selectEduDegrees = selectedOptions;
        setSelectEduDegrees(selectEduDegrees);
    };
    console.log(ID)
    const [jobRoles, setJobRoles] = useState([])
    useEffect(() => {
        const fetchJobRoles = async () => {
            const data = await fetchAllJobRoles(authToken1);
            setJobRoles(data.data)
        }
        fetchJobRoles()
    }, [authToken1])

    const handleDomain = () => {
        console.log('options')
    }

    const [hasError, setHasError] = useState({
        'selectEduLevels': false,
        'selectEduDegrees': false,
        'selectXp': false,
        'selectProfile': false,
        'selectIncome': false,
        'selectDomain': false
    })

    const showErrorMsg = (e) => {
        const showError = { ...hasError }
        console.log(showError)
        if (e === "Select Education") {
            console.log(e)
        }
        if (e === "Select Experience") {
            console.log(e)
        }
        if (e === "Select Profile") {
            console.log(e)
        }
        if (e === "Select CTC") {
            console.log(e)
        }
        if (e === "Select Domain") {
            console.log(e)
        }
    }

    function submitForm(e) {
        e.preventDefault();
        console.log('running');

        console.log(completeProfileStep)
        const newStep1RegData = {
            'selectEduLevels': selectEduLevels,
            'selectEduDegrees': selectEduDegrees,
            'selectXp': selectXp,
            // 'selectProfile': selectProfile,
            'selectIncome': selectIncome,
            'selectDomain': selectDomain,
            'SelectCollege': selectedCollege,
            'currentJob': currentJob,
            'jobDomain': jobDomain,
            'stepNo': 1,
            'completeProfileStep': completeProfileStep,

        }
        console.log('newStep1RegData: ', newStep1RegData)
        submitFormStep1(newStep1RegData)

        setValidated(true);
    }




    // const handleGoBack = () => {
    //     console.log('handel go back fuc triggerd!!!')
    //     const updateStep = {
    //         'stepNo': -2,
    //         'completeProfileStep': ID,
    //     }
    //     updateRegistrationStep(updateStep);
    //     navigate('*');
    // }

    // filtered out duplicate domains

    const handleGoBack = async () => {
        try {
            console.log('handle go back function triggered!!!');

            // Define the update step object
            const updateStep = {
                'stepNo': -2,
                'completeProfileStep': ID,
            };

            // Assuming updateRegistrationStep is an async function
            await updateRegistrationStep(updateStep);

            // Navigate to the desired page after the update is done
            navigate('*');
        } catch (error) {
            console.error("Error in handleGoBack:", error);
        }
    };




    const uniqueDomain = Array.from(new Set(jobRoles
        .filter(role => role.AppVisibility)
        .map(role => role.Domain)
    ))
        .map(domain => jobRoles.find(role => role.Domain === domain && role.AppVisibility));

    // new ui functionality start
    const [getUserData, setGetUserData] = useState({
        'currentJob': '',
        'experience': '',
        'jobDomin': '',
        'college': '',
        'education': '',
        'studyField': '',
        'DomainOfIntrest': '',
        'stepNo': 1,
        'completeProfileStep': completeProfileStep
    });
    console.log('getUserData: ', getUserData);


    const updateUserData = (e) => {
        const field = e.target.name;
        console.log('field: ', field);

        setGetUserData(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    }

    const handelSubmit = (e) => {
        e.preventDefault();
        updateRegistrationStep(getUserData);
    }
    // new ui functionality end




    return (
        <>
            {/* old ui code start */}
            {/* <div className="section mb-2 mt-5 full">
                <div className="wide-block pt-5 pb-2" style={{ borderBottom: "none" }}>
                    <Form noValidate validated={validated} onSubmit={submitForm}>
                        <Form.Group className='boxed mb-2'>
                            <Form.Label htmlFor="eduLevel1">Select Education Level</Form.Label>
                            <InputGroup className="boxed mb-2 position-relative">
                                <InputGroup.Text id="basic-addon1"><i className="fa fa-graduation-cap"></i></InputGroup.Text>
                                <Form.Select
                                    required
                                    id="eduLevel1"
                                    // options={eduLevelOptions}
                                    value={selectEduLevels}
                                    onChange={(e) => { handleSelectedEduLevels(e.target.value) }}
                                >
                                    <option value="" disabled>Select Education Level</option>
                                    {getEduLevels.map((level) => (
                                        <option key={level.Level} value={level.Level}>{level.Level}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="boxed mb-2">
                            <Form.Label for="eduDegrees1">Select Education Degree</Form.Label>
                            <InputGroup className="boxed mb-2 position-relative">
                                <InputGroup.Text id="basic-addon1"><i className="fa fa-graduation-cap"></i></InputGroup.Text>
                                <Form.Select
                                    aria-label="Default select example"
                                    id="eduDegrees1"
                                    value={selectEduDegrees}
                                    required
                                    // options={eduDegreeOptions}
                                    onChange={(e) => { handleSelectedEduDegrees(e.target.value) }}
                                >
                                    <option value="" disabled>Select Education Degree</option>
                                    {getEduDegrees.map((level) => (
                                        <option key={level.Education} value={level.Education}>
                                            {level.Education}
                                        </option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>

                        {candidateData.ExperienceLevel === 'Experienced' && (
                            <Form.Group className="boxed mb-2">
                                <Form.Label for="xpLevel1">Select Years of experience</Form.Label>
                                <InputGroup className="boxed mb-2 position-relative">
                                    <InputGroup.Text id="basic-addon1"><i className="fa fa-business-time"></i></InputGroup.Text>
                                    <Form.Select
                                        aria-label="Default select example"
                                        id="xpLevel1"
                                        value={selectXp}
                                        required
                                        onChange={(e) => setSelectXp(e.target.value)}
                                    >
                                        <option value="" disabled>Select Years of experience</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </Form.Select>
                                </InputGroup>
                            </Form.Group>
                        )}

                        {candidateData.ExperienceLevel === 'Experienced' && (
                            <Form.Group className="boxed mb-2">
                                <Form.Label for="incomeLevel1">Current Yearly Income</Form.Label>
                                <InputGroup className="boxed mb-2 position-relative">
                                    <InputGroup.Text id="basic-addon1"><i className="fa fa-rupee-sign"></i></InputGroup.Text>
                                    <Form.Select
                                        aria-label="Default select example"
                                        id="incomeLevel1"
                                        value={selectIncome}
                                        required
                                        onChange={(e) => setSelectIncome(e.target.value)}
                                    >
                                        <option value="" disabled>Current Monthly Income</option>
                                        <option value="1">1 Lakh</option>
                                        <option value="2">2 Lakh</option>
                                        <option value="3">3 Lakh</option>
                                        <option value="4">4 Lakh</option>
                                        <option value="5">5 Lakh</option>
                                        <option value="6">6 Lakh</option>
                                        <option value="7">7 Lakh</option>
                                        <option value="8">8 Lakh</option>
                                        <option value="9">9 Lakh</option>
                                        <option value="10">10 Lakh</option>
                                        <option value="11">11 Lakh</option>
                                        <option value="12">12 Lakh</option>
                                        <option value="13">13 Lakh</option>
                                        <option value="14">14 Lakh</option>
                                        <option value="15">15 Lakh</option>
                                        <option value="16">16 Lakh</option>
                                        <option value="17">17 Lakh</option>
                                        <option value="18">18 Lakh</option>
                                        <option value="19">19 Lakh</option>
                                        <option value="20">20 Lakh</option>
                                    </Form.Select>
                                </InputGroup>
                            </Form.Group>
                        )}

                        <Form.Group className="boxed mb-2">
                            <Form.Label htmlFor="domainLevel1">Select Domain of your interest</Form.Label>
                            <InputGroup className="boxed mb-2 position-relative">
                                <InputGroup.Text id="basic-addon1"><i className="fa fa-list"></i></InputGroup.Text>
                                <Form.Select
                                    // options={uniqueDomain.map((role) => ({ value: role.ID, label: role.Domain }))}
                                    // onChange={(selectedOption) => setSelectDomain(selectedOption.value)}
                                    required
                                    onChange={(e) => setSelectDomain(e.target.value)}
                                    value={selectDomain}
                                    placeholder="Select Domain"
                                    isSearchable
                                >
                                    <option value="" disabled>Select Domain</option>
                                    {uniqueDomain.map((role) => (
                                        <option key={role.ID} value={role.ID}>{role.Domain}</option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Form.Group>
                        <Row>
                            <Col>
                                <Button type="button" onClick={handleGoBack} className="btn btn-primary btn-lg w-100 mx-1"><i className="fa fa-chevron-left" style={{ fontSize: "14px", paddingRight: "5px" }}></i>Back</Button>
                            </Col>
                            <Col>
                                <Button type="submit" className="btn btn-primary btn-lg w-100 mx-1">Next<i className="fa fa-chevron-right" style={{ fontSize: "14px", paddingLeft: "5px" }}></i></Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div> */}
            {/* old ui code end */}


            {/* {newUiCandidateEducationStep ? (<> */}



            {/* candidate step 4/6 page start */}
            <section>
                <div className='step-1-section-sub-div'>
                    <div className='h-100'>
                        <Row className='h-100 w-100'>
                            <Col md={8} sm={12} className='step-1-col-div h-100'>
                                <form onSubmit={submitForm}>
                                    <div>
                                        <img src={Logo} style={{ height: '2.5rem' }} ></img>
                                    </div>
                                    <div className='d-flex gap-2 mt-2'>
                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#C6E8DF', borderRadius: '5px' }}></div>
                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#358D77', borderRadius: '5px' }}></div>
                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                        <div style={{ width: '100px', height: '8px', backgroundColor: '#E5E5E5', borderRadius: '5px' }}></div>
                                    </div>
                                    <span class="" style={{ fontSize: '14px' }}>4 of 6</span>
                                    <div className="d-flex flex-column">
                                        {candidateData?.ExperienceLevel === 'Experienced' ?
                                            <>
                                                <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Add Your Work & Education Details</h2>
                                                <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>Showcase your expertise.</lable>
                                            </>
                                            :
                                            <>
                                                <h2 className='mb-0 ms-2' style={{ marginTop: '3rem' }}>Add Your Education Details</h2>
                                                <lable className='ms-2' style={{ color: '#737373', text: 'start' }}>Add your academic info.</lable> </>}

                                    </div>

                                    <div className="ms-2" style={{ marginTop: '2rem' }}>
                                        {candidateData?.ExperienceLevel === 'Experienced' &&
                                            <>
                                                <Row>
                                                    <Col md={6} sm={12}>
                                                        <div>
                                                            {/* <Form.Group className="boxed mb-2" >
                                                                <Form.Label for="xpLevel1">Current Job Title</Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Form.Select
                                                                        // options={uniqueDomain.map((role) => ({ value: role.ID, label: role.Domain }))}
                                                                        // onChange={(selectedOption) => setSelectDomain(selectedOption.value)}
                                                                        style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}

                                                                        onChange={(e) => setCurrentJob(e.target.value)}
                                                                        value={jobRoles.find(
                                                                            (option) => option.value === jobRoles
                                                                        )}
                                                                        // value={currentJob}
                                                                        placeholder="Select Domain"
                                                                        isSearchable
                                                                    >
                                                                        <option value="" disabled selected>Select...</option>
                                                                        {jobRoles?.map((role) => (
                                                                            <option key={role.ID} value={role.ID}>{role.Title}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </InputGroup>
                                                            </Form.Group> */}
                                                            <Form.Group className="boxed mb-2">
                                                                <Form.Label htmlFor="xpLevel1">Current Job Title</Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Select
                                                                        options={jobRoles?.map((role) => ({ value: role.ID, label: role.Title }))} // Pass the options
                                                                        onChange={(selectedOption) =>
                                                                            setCurrentJob(selectedOption?.value)
                                                                        } // Update state on selection
                                                                        value={jobRoles?.find(
                                                                            (option) => option?.value === currentJob
                                                                        )} // Bind the current value to state
                                                                        placeholder="Select Domain"
                                                                        isSearchable // Enable search
                                                                        styles={{
                                                                            control: (base) => ({
                                                                                ...base,
                                                                                height: '2.5rem',
                                                                                borderRadius: '5px',
                                                                                border: '1px solid #E5E5E5',
                                                                            }),
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <div>
                                                            <Form.Group className="boxed mb-2" >
                                                                <Form.Label for="xpLevel1">Years of Experience </Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Form.Select

                                                                        style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                        aria-label="Default select example"
                                                                        id="xpLevel1"
                                                                        name="experience"
                                                                        value={selectXp}

                                                                        onChange={(e) => setSelectXp(e.target.value)}
                                                                        isSearchable
                                                                    >
                                                                        <option value="" selected disabled>Select...</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                        <option value="6">6</option>
                                                                        <option value="7">7</option>
                                                                        <option value="8">8</option>
                                                                        <option value="9">9</option>
                                                                        <option value="10">10</option>
                                                                        <option value="11">11</option>
                                                                        <option value="12">12</option>
                                                                        <option value="13">13</option>
                                                                        <option value="14">14</option>
                                                                        <option value="15">15</option>
                                                                        <option value="16">16</option>
                                                                        <option value="17">17</option>
                                                                        <option value="18">18</option>
                                                                        <option value="19">19</option>
                                                                        <option value="20">20</option>
                                                                    </Form.Select>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} sm={12}>
                                                        <div>
                                                            {/* <Form.Group className="boxed mb-2" >
                                                                <Form.Label for="xpLevel1">Current Job Domain</Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Form.Select
                                                                        options={uniqueDomain.map((role) => (
                                                                            <option key={role.ID} value={role.ID}>{role.Domain}</option>
                                                                        ))}
                                                                        style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                        onChange={(e) => setJobDomain(e.target.value)}
                                                                        
                                                                        value={jobDomain}
                                                                        placeholder="Select Domain"
                                                                        isSearchable
                                                                    >
                                                                        <option value="" disabled selected>Select...</option>
                                                                        {uniqueDomain.map((role) => (
                                                                            <option key={role.ID} value={role.ID}>{role.Domain}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </InputGroup>
                                                            </Form.Group> */}

                                                            <Form.Group className="boxed mb-2">
                                                                <Form.Label htmlFor="xpLevel1">Current Job Domain</Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Select
                                                                        // options={uniqueDomain} // Pass domain options
                                                                        options={uniqueDomain.map((role) => ({ value: role.ID, label: role.Domain }))} // Pass domain options
                                                                        onChange={(selectedOption) =>
                                                                            setJobDomain(selectedOption?.value)
                                                                        } // Update state on selection
                                                                        value={uniqueDomain?.find(
                                                                            (option) => option?.value === jobDomain
                                                                        )} // Bind current value to state
                                                                        placeholder="Select Domain"
                                                                        isSearchable // Enable search functionality
                                                                        styles={{
                                                                            control: (base) => ({
                                                                                ...base,
                                                                                height: '2.5rem',
                                                                                borderRadius: '5px',
                                                                                border: '1px solid #E5E5E5',
                                                                            }),
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md={6} sm={12}>
                                                        <div>
                                                            <Form.Group className="boxed mb-2">
                                                                <Form.Label htmlFor="xpLevel1">Institute/College</Form.Label>
                                                                <InputGroup className="boxed position-relative">
                                                                    <Select
                                                                        options={getColleges.map((role) => ({ value: role.NameAddressPinCode, label: role.NameAddressPinCode }))}
                                                                        onChange={(selectedOption) => setSelectedCollege(selectedOption?.value)}
                                                                        value={selectedCollege ? { value: selectedCollege, label: selectedCollege } : null}
                                                                        placeholder="Select College"
                                                                        isSearchable
                                                                        styles={{
                                                                            control: (base) => ({
                                                                                ...base,
                                                                                height: '2.5rem',
                                                                                borderRadius: '5px',
                                                                                border: '1px solid #E5E5E5',
                                                                            }),

                                                                            //                                                                               hussain's  code
                                                                            menu: (base) => ({
                                                                                ...base,
                                                                                zIndex: 5, // Ensure dropdown appears above other UI elements
                                                                            }),
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                        <Row>
                                            {/* <Col md={6} sm={12}>
                                                <div>
                                                    <Form.Group className="boxed mb-2">
                                                        <Form.Label htmlFor="xpLevel1">Institute/College<span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <InputGroup className="boxed position-relative">
                                                            <Select
                                                                options={eduLevelOptions}
                                                                onChange={(selectedOption) =>
                                                                    handleSelectedEduLevels(selectedOption?.value)
                                                                } // Update state on selection
                                                                value={eduLevelOptions.find(
                                                                    (option) => option === selectEduLevels
                                                                )} // Bind selected value
                                                                placeholder="Select Institute"
                                                                isSearchable // Enable search functionality
                                                                styles={{
                                                                    control: (base) => ({
                                                                        ...base,
                                                                        height: '2.5rem',
                                                                        borderRadius: '5px',
                                                                        border: '1px solid #E5E5E5',
                                                                    }),
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                            </Col> */}
                                            <Col md={6} sm={12}>
                                                <div>
                                                    {/* <Form.Group className="boxed mb-2" >
                                                        <Form.Label for="xpLevel1">Highest Education Completed</Form.Label>
                                                        <InputGroup className="boxed position-relative">
                                                            <Form.Select
                                                                required
                                                                style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                id="eduLevel1"
                                                                // options={eduLevelOptions}
                                                                value={selectEduLevels}
                                                                onChange={(e) => { handleSelectedEduLevels(e.target.value) }}
                                                            >
                                                                <option value="" disabled selected>Select...</option>
                                                                {getEduLevels.map((level) => (
                                                                    <option key={level.Level} value={level.Level}>{level.Level}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Form.Group> */}

                                                    <Form.Group className="boxed mb-2">
                                                        <Form.Label htmlFor="xpLevel1">Highest Education Completed<span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <InputGroup className="boxed position-relative">
                                                            {console.log(eduLevelOptions)}
                                                            <Select
                                                                options={eduLevelOptions}
                                                                onChange={(selectedOption) =>
                                                                    handleSelectedEduLevels(selectedOption?.value)
                                                                } // Update state on selection
                                                                value={eduLevelOptions.find(
                                                                    (option) => option === selectEduLevels
                                                                )} // Bind selected value
                                                                placeholder="Select Education Level"
                                                                isSearchable // Enable search functionality
                                                                styles={{
                                                                    control: (base) => ({
                                                                        ...base,
                                                                        height: '2.5rem',
                                                                        borderRadius: '5px',
                                                                        border: '1px solid #E5E5E5',
                                                                    }),
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col md={6} sm={12}  >
                                                <div>
                                                    {/* <Form.Group className="boxed mb-2" >
                                                        <Form.Label for="xpLevel1">Field of Study</Form.Label>
                                                        <InputGroup className="boxed position-relative">
                                                            <Form.Select
                                                                style={{ height: '2.5rem', borderRadius: '5px', border: '1px solid #E5E5E5' }}
                                                                aria-label="Default select example"
                                                                id="eduDegrees1"
                                                                value={selectEduDegrees}
                                                                required
                                                                // options={eduDegreeOptions}
                                                                onChange={(e) => { handleSelectedEduDegrees(e.target.value) }}
                                                            >
                                                                <option value="" disabled selected>Select...</option>
                                                                {getEduDegrees.map((level) => (
                                                                    <option key={level.Education} value={level.Education}>
                                                                        {level.Education}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </Form.Group> */}
                                                    <Form.Group className="boxed mb-2">
                                                        <Form.Label htmlFor="xpLevel1">Field of Study<span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <InputGroup className="boxed position-relative">
                                                            <Select
                                                                options={eduDegreeOptions} // Array of { value, label } options
                                                                onChange={(selectedOption) =>
                                                                    handleSelectedEduDegrees(selectedOption?.value)
                                                                } // Update state on selection
                                                                value={eduDegreeOptions.find(
                                                                    (option) => option === selectEduDegrees
                                                                )} // Bind selected value to state
                                                                placeholder="Select Education Level"
                                                                isSearchable // Enables search functionality
                                                                styles={{
                                                                    control: (base) => ({
                                                                        ...base,
                                                                        height: '2.5rem',
                                                                        borderRadius: '5px',
                                                                        border: '1px solid #E5E5E5',
                                                                    }),
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        zIndex: 5, // Ensure the dropdown is above other UI elements
                                                                    }),
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>


                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="ms-2" style={{ marginTop: '1rem' }}>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <div>
                                                    <Form.Group className="boxed mb-2">
                                                        <Form.Label htmlFor="xpLevel1">Domains of Interest<span style={{ color: 'red' }}>*</span></Form.Label>
                                                        <InputGroup className="boxed position-relative">
                                                            <Select
                                                                options={uniqueDomain.map((role) => ({
                                                                    value: role.ID,
                                                                    label: role.Domain,
                                                                }))} // Map uniqueDomain to value-label pairs
                                                                onChange={(selectedOption) => setSelectDomain(selectedOption?.value)} // Update state with selected value
                                                                value={uniqueDomain.find((role) => role.ID === selectDomain)
                                                                    ? { value: selectDomain, label: uniqueDomain.find((role) => role.ID === selectDomain)?.Domain }
                                                                    : null
                                                                } // Display the selected option
                                                                placeholder="Select Domain"
                                                                isSearchable // Enable search functionality
                                                                styles={{
                                                                    control: (base) => ({
                                                                        ...base,
                                                                        height: '2.5rem',
                                                                        borderRadius: '5px',
                                                                        border: '1px solid #E5E5E5',
                                                                    }),
                                                                    menu: (base) => ({
                                                                        ...base,
                                                                        zIndex: 5, // Ensure dropdown appears above other UI elements
                                                                    }),
                                                                }}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="ms-2 mt-3 d-flex justify-content-between">
                                        <div>
                                            <button type="button" onClick={handleGoBack} className="btn btn-outline-primary" style={{ height: '48px', fontSize: '20px' }}><ArrowLeft className="me-1" style={{ height: '15px', width: '15px' }} /> Back <span className="ms-1"></span></button>
                                        </div>
                                        <div>
                                            <Button type='submit' className="btn" style={{ backgroundColor: '#358D77', color: 'white', height: '48px', fontSize: '20px' }}>Next<span className="ms-1"><ArrowRight style={{ height: '15px', width: '15px' }} /></span></Button>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                            <Col md={4} sm={12} className='new-ui-otp-col-2'>
                                <SideValiDidi />
                            </Col>
                        </Row>
                    </div>
                </div>
            </section >
            {/* candidate step 4/6 page end */}



            {/* </>)
                :
                (<>
                    <h1>else working</h1></>)
            } */}


        </>
    )
}

const mapStateToProps = ({ auth }) => {
    const { xpLevel, authToken, ID, authToken1 } = auth

    return {
        xpLevel, authToken1, ID
    }
}
const mapDispatchToProps = {
    setXpLevel,
    setToken,
    updateRegistrationStep
}
export default connect(mapStateToProps, mapDispatchToProps)(RegFormStep1)
