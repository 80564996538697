const config = {
  // appUrl : "http://localhost:3000",
  // apiUrl : "https://api.scopehai.com"
  // apiUrl : "https://www.scopehai.com/api",
     apiUrl: 'https://beta.scopehai.com/api'
  // apiUrl: 'http://192.168.0.113:3005/api' //Pratik DELL
  // apiUrl : "http://localhost:4444/api"
  // apiUrl: 'http://localhost:3005/api'
}
export default config
