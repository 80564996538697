import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { hideScopeDetailModal } from '../../../features/popup/ScopeDetailModalSlice';
import SampleCertificate from '../../images/icon/certificate.png'
import moment from 'moment';
import { createJobApplication } from '../../../features/constants/apis';
import { ToastContainer, toast } from 'react-toastify';

const ScopeDetailModal = ({ scopeDetailModalVisible, hideScopeDetailModal, scopeDetailData, scopeType, ID, authToken }) => {
    console.log(scopeDetailData)
    const [skillsArr, setSkillsArr] = useState([])
    const [applyButtonDisable, setApplyButtonDisable] = useState(false)
    useEffect(() => {
        if (scopeType === 'drives') {
            const skillsArray = scopeDetailData.Criteria.split(",").map(skill => skill.trim());
            setSkillsArr(skillsArray)
        }
    }, [scopeDetailData, scopeType])
    const handleApplyJob = (e, jobData) => {
        setApplyButtonDisable(true)
        const data = {
            'EmpID': null,
            'JobID': jobData.ID,
            'JobTitle': jobData.Title,
            'placementID': null,
            'tokenUserID': ID
        }

        const jobs = async () => {
            
            const jobData = await createJobApplication(data, authToken)

            if (jobData?.data === "Job appliation created successfully!") {
                setApplyButtonDisable(false)
                hideScopeDetailModal()
                toast.success('Your Skill profile has been shared with the recruiter!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
            }
            // console.log(jobData?.data)
        }
        jobs()
    }
    const navigate = useNavigate();
    const navigateTo = (URL) => {
        navigate(URL)
    }
    const handleJobsRedirection = () => {
        hideScopeDetailModal()
        navigateTo('/jobs')
    }
    return (
        <div>
            <ToastContainer />
            <Modal show={scopeDetailModalVisible} centered onHide={() => { hideScopeDetailModal() }}>
                {scopeType === 'jobs' &&
                    <Modal.Body>
                        <div class="d-flex justify-content-between mb-3">
                            <h3 class="mb-0">Scope Detail</h3>
                            <span class="cursor-pointer" onClick={() => { hideScopeDetailModal() }}>
                                <X />
                            </span>
                        </div>
                        <div class="mb-3">
                            <h4 class="fw-bold" style={{ marginBottom: '5px' }}>{scopeDetailData?.Title}</h4>
                            <h5 class="mb-0">{scopeDetailData?.Experience} exp - {scopeDetailData?.Location}</h5>
                        </div>
                        <div class="mb-3">
                            <h5 class='mb-1 fw-bold'>Job Description</h5>
                            <p class="text-muted" style={{ fontSize: '13px', lineHeight: '1.6' }}>
                                {scopeDetailData?.Description}
                            </p>
                        </div>
                        <div class="d-flex justify-content-end">
                            {scopeDetailData.Status != 'Applied' &&
                                <button class="btn bg-primary text-white" disabled={applyButtonDisable} onClick={(e) => {handleApplyJob(e, scopeDetailData)}}>Apply</button>
                            }
                            {scopeDetailData.Status == 'Applied' &&
                                <button class="btn bg-primary text-white" disabled>Applied</button>
                            }
                        </div>
                    </Modal.Body>
                }
                {scopeType === 'drives' &&
                    <Modal.Body>
                        <div class="d-flex justify-content-between mb-3">
                            <h3 class="mb-0">Scope Detail</h3>
                            <span class="cursor-pointer" onClick={() => { hideScopeDetailModal() }}>
                                <X />
                            </span>
                        </div>
                        <div class="mb-3 d-flex justify-content-between">
                            <div>
                                <h4 class="fw-bold" style={{ marginBottom: '5px' }}>{scopeDetailData?.Title}</h4>
                                <h5 class="mb-0">{scopeDetailData?.Qualification} - {scopeDetailData?.Location}</h5>
                            </div>
                            <div>
                                <h6 class="mb-0 text-secondary">{moment(scopeDetailData?.FromDate).format("MMM Do YY")} - {moment(scopeDetailData?.ToDate).format("MMM Do YY")}</h6>
                            </div>
                        </div>
                        <div class="mb-3">
                            <h5 class='mb-1 fw-bold'>Skills</h5>
                            <div class="flex-wrap">
                                {skillsArr?.map((v1, k1) => {
                                    return <span class="me-1 badge border text-black text-start" style={{ textWrap: 'auto' }}>{v1}</span>
                                })}
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button onClick={() => handleJobsRedirection()} class="btn btn-outline-primary">View</button>
                        </div>
                    </Modal.Body>
                }
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ scopeDetail, auth }) => {
    const { scopeDetailModalVisible, scopeDetailData, scopeType } = scopeDetail
    const { ID, authToken } = auth

    return {
        scopeDetailModalVisible,
        scopeDetailData,
        scopeType,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    hideScopeDetailModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ScopeDetailModal);