import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { History } from 'lucide-react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideSearchHistoryOffcanvas } from '../../../features/popup/offcanvasSlice';
import moment from 'moment-timezone'

const SearchHistoryOffcanvas = ({
    searchHistoryOffcanvasVisible,
    hideSearchHistoryOffcanvas,
    searchData
}) => {
    let navigate = useNavigate();
    const handleSearchHistory = (searchHistoryData) => {
        console.log(searchHistoryData)
        navigate(`/emp-candidate-list?term=${searchHistoryData.Filters.term}&sortBy[]=${searchHistoryData.Filters.sortBy[0]}&pageSize=${searchHistoryData.Filters.pageSize}&pageIndex=${searchHistoryData.Filters.pageIndex}&isVsp=${searchHistoryData.Filters.isVsp}&year=${searchHistoryData.Filters.year}&salary=${searchHistoryData.Filters.salary}&immediate=${searchHistoryData.Filters.immediate}`);
        hideSearchHistoryOffcanvas();
    }
  return (
    <div>
        <Offcanvas show={searchHistoryOffcanvasVisible} placement={'end'} backdrop={'static'} style={{width: '310px'}}>
            <Offcanvas.Body>
                <div class="d-flex align-items-center justify-content-between">
                    <h4 class="d-flex align-items-center fw-bold mb-0"><History size={18} class="text-primary" />&nbsp;&nbsp;Search History</h4>
                    <span class="cursor-pointer d-flex align-items-center" onClick={() => {hideSearchHistoryOffcanvas()}}><ion-icon name="close-outline"></ion-icon></span>
                </div>
                <div class="mt-1">
                    {/* <ul class="nav nav-tabs capsuled" role="tablist" style={{height: '32px'}}>
                        <li class="nav-item" style={{height: '32px'}}>
                            <a class="nav-link active" data-bs-toggle="tab" href="#simpleHistory" style={{height: '24px', fontSize: '11px'}} role="tab">
                                Simple History
                            </a>
                        </li>
                        <li class="nav-item" style={{height: '32px'}}>
                            <a class="nav-link" data-bs-toggle="tab" href="#detailedHistory" style={{height: '24px', fontSize: '11px'}} role="tab">
                                Detailed History
                            </a>
                        </li>
                    </ul> */}

                    <div class="tab-content mt-1">
                        {/* <div id="simpleHistory" class="tab-pane fade in active show"> */}
                            <ul class="p-0 mt-1 mb-0" style={{listStyleType: 'none', height: '85vh', overflow: 'scroll'}}>
                                {searchData?.searchLogs?.map((v,k) => {
                                    return (
                                        <li class="border-bottom cursor-pointer" style={{padding: '7px 0px 0px 7px'}} onClick={() => {handleSearchHistory(v)}}>
                                            <div class="row">
                                                <div class="col-8 col-md-8">
                                                    <h6 class="mb-0">{v.Filters.term}</h6>
                                                    <p class="fs-10 text-secondary mb-0" style={{marginTop: '-2px'}}>Page no {v.Filters.pageIndex}</p>
                                                </div>
                                                <div class="col-4 col-md-4 d-flex justify-content-end align-items-center">
                                                    <p class="fs-10 text-secondary mb-0 text-nowrap" style={{marginTop: '-2px'}}>{moment.utc(v.CreatedOn).tz('Asia/Kolkata').fromNow()}</p>&nbsp;&nbsp;&nbsp;
                                                    <ion-icon name="chevron-forward-outline" class="text-secondary" style={{marginTop: '-2px'}}></ion-icon>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </div>
  )
}

const mapStateToProps = ({ offcanvas }) => {
    const { searchHistoryOffcanvasVisible, searchData } = offcanvas
    return {
        searchHistoryOffcanvasVisible,
        searchData
    }
}

const mapDispatchToProps = {
    hideSearchHistoryOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchHistoryOffcanvas)
