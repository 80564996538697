import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../AxiosConfig'
import config from '../../config'
import jwt_decode from 'jwt-decode'
import { getContrastRatio } from '@mui/material'

export const requestOtp = createAsyncThunk(
  'auth/requestOtp',
  async (params) => {
    const { mobileNumber, role, email } = params
    console.log("mob No: ", mobileNumber);
    const response = await axios.get(config.apiUrl + '/user/login?mobile=' + mobileNumber + '&role=' + role + '&email=' + email)
    const { data } = response
    return data
  }
)

export const validateOtp = createAsyncThunk(
  'auth/validateOtp',
  async (params) => {
    const { mobileNumber, otp, role, email, emailOtp } = params
    const response = await axios.get(config.apiUrl + '/user/login?mobile=' + mobileNumber + '&otp=' + otp + '&role=' + role + '&email=' + email + '&emailOtp=' + emailOtp)
    const { data } = response
    return data
  }
)

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (params) => {
    console.log('register user api func triggerd');

    const { ID, step, expLevel } = params;
    console.log('ID, step, expLevel: ', ID, step, expLevel);

    const response = await axios.put(config.apiUrl + '/user/register/' + step + '/' + ID, params)
    const { data } = response
    return data
  }
)
export const addUser = createAsyncThunk(
  'auth/addUser',
  async (params) => {
    console.log('add user api fuc triggerd');
    const response = await axios.post(config.apiUrl + '/user/register', params)
    const { data } = response
    return data
  }
)

export const registerEmp = createAsyncThunk(
  'auth/registerEmp',
  async (params) => {
    const response = await axios.post(config.apiUrl + '/user/register', params)
    const { data } = response
    return data
  }
)

export const submitFormStep1 = createAsyncThunk(
  'auth/submitFormStep1',
  async (params) => {
    console.log('runningggggggggggggggggggggggggggggggggggggggggg', params);

    const { selectEduLevels, selectEduDegrees, selectXp, selectIncome, selectDomain, currentJob, jobDomain, stepNo, completeProfileStep, SelectCollege } = params;
    const careerData = {
      'education': selectEduLevels,
      'degree': selectEduDegrees,
      'experience': selectXp,
      // 'jobProfile': selectProfile,
      'incomeCTC': selectIncome,
      'domain': selectDomain,
      'currentJob': currentJob,
      'currentDomain': jobDomain,
      'college': SelectCollege,
    }
    console.log('careerData: ', careerData);
    const response = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + completeProfileStep.userInfo.ID, { careerData })
    const { data } = response
    return data
  }
)






export const submitFormStep2 = createAsyncThunk(
  'auth/submitFormStep2',
  async (params) => {
    const { selectGSkills, selectPSkills, uploadedFile, stepNo, completeProfileStep, selectNotification } = params;
    const skillsData = {
      'GeneralSkills': selectGSkills,
      'ProfessionalSkills': selectPSkills,
      'Certificate': uploadedFile
    };
    const notificationData = {
      'notifications': selectNotification
    };
    console.log(axios)
    const response = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + completeProfileStep.userInfo.ID, { skillsData, notificationData })
    const { data } = response
    return data
  }
)
export const domainJobrolesStep3 = createAsyncThunk(
  'auth/domainJobrolesStep3',
  async (params) => {
    const { stepNo, ID, domainJobRoles } = params;
    const responase = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + ID, domainJobRoles)
    const { data } = responase
    return data
  }
)
export const submitFormStep3 = createAsyncThunk(
  'auth/submitFormStep3',
  async (params) => {
    console.log('submitFormStep3 fuc triggerd in authslice', params);

    const { selectedJobRoles, stepNo, completeProfileStep } = params;
    console.log(completeProfileStep)
    // const notificationData = {
    //   'notifications': selectNotification
    // }
    console.log('console before api call!!!', axios)
    const response = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + completeProfileStep, { selectedJobRoles })
    console.log('console after api call!!!', response)

    const { data } = response
    return data
  }
)

export const updateRegistrationStep = createAsyncThunk(
  'auth/updateRegistrationStep',
  async (params) => {
    console.log('updateRegistrationStep', params)
    const { stepNo, completeProfileStep } = params;
    console.log("StepNo:", stepNo, completeProfileStep);
    const response = await axios.put(config.apiUrl + '/user/register/updateRegStep/' + completeProfileStep, { newStep: stepNo })
    const { data } = response
    console.log("Update Reg Resp:", response);
    return data
  }
)

const initialState = {
  authToken: null,
  hideSendOtpButton: false,
  hideSendOtpButton1: false,
  showVerifyOtpForm: false,
  showLoginForm: true,
  showRegistrationForm: false,
  otpRequestSuccess: false,
  otpRequestError: false,
  otpValidateSuccess: false,
  otpValidateError: false,
  role: null,
  fullName: null,
  showRegistrationStep: null,
  completeProfileStep: null,
  ID: null,
  appUrlCourseID: null,
  xpLevel: null,
  registerCandidate: false,
  empRegistrationForm: false,
  showEmpRegistrationForm: false,
  showEmpDashboard: false,
  showEmpVerifyOtpForm: false,
  newShowEmpRegistrationForm: false,
  showEmpLogin: true,
  //////////////////// states for new UI start///////////////////////
  newUiCandidateLoginStep: false,
  newUiCandiateOtpStep: false,
  newUiCandidateRegistrationStep: false,
  newUiCandidateExperience: false,
  newUiCandidateSkillStep: false,
  newUiCandidateOpportunitiesStep: false,
  newUiCandidateEducationStep: false,
  message: null,
  verificationOtpMessage: null,


  //////////////////// states for new UI end///////////////////////
  themeValues: {
    primary: "#45A994",
    secondary: "#F7BD4E",
    tertiary: "#CDFFD8",
    quaternary: "#FEF6D8",
    body: "#4e4e4e",
    alternate: "#7c7c7c",
    lightText: "#fff",
    warning: "#ebb71a",
    danger: "#cf2637",
    success: "#439b38",
    info: "#279aac",
    font: "'League Spartan', sans-serif",
    fontHeading: "'League Spartan', sans-serif",
    background: "#f9f9f9",
    foreground: "#ffffff",
    separator: "#dddddd",
    separatorLight: "#f1f1f1",
    primaryrgb: "30, 168, 231",
    secondaryrgb: "108, 219, 239",
    tertiaryrgb: "93, 227, 200",
    quaternaryrgb: "85, 141, 243",
    transitionTimeShort: "200",
    transitionTime: "400",
    navSizeSlim: "5rem",
    borderRadiusXl: "50px",
    borderRadiusLg: "16px",
    borderRadiusMd: "10px",
    borderRadiusSm: "6px",
    spacingHorizontal: "2.5rem",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
    direction: "ltr"
  }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      console.log('Logout auth')
      localStorage.clear()
      state.hideSendOtpButton = false
      state.hideSendOtpButton1 = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.showRegistrationForm = false
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.authToken = null
      state.role = null
      state.fullName = null
      state.ID = null
      state.showEmpVerifyOtpForm = false
    },
    backToLogin: (state) => {
      state.submitFormStep2 = false
      state.hideSendOtpButton = false
      state.hideSendOtpButton1 = false
      state.showVerifyOtpForm = false
      state.showLoginForm = true
      state.showRegistrationForm = false
      state.otpRequestSuccess = false
      state.otpRequestError = false
      state.otpValidateSuccess = false
      state.otpValidateError = false
      state.role = null
      state.fullName = null
      state.ID = null
      state.showEmpVerifyOtpForm = null

      console.log('Back To Login')
      localStorage.clear()
    },
    setToken: (state, action) => {
      const tokenData = jwt_decode(action.payload.authToken);
      if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3) {
        state.showRegistrationStep = tokenData.userInfo.registrationStep
        console.log(tokenData.userInfo.registrationStep)
        state.authToken = action.payload.authToken
        state.role = tokenData.userInfo.RoleID;
        state.fullName = tokenData.userInfo.FullName;
        state.ID = tokenData.userInfo.ID;
      } else if (tokenData.userInfo.registrationStep === 5) {
        state.showRegistrationStep = tokenData.userInfo.registrationStep
        console.log("registration step :", tokenData.userInfo.registrationStep)
        state.authToken = action.payload.authToken
        state.role = tokenData.userInfo.RoleID;
        console.log("registration role :", tokenData.userInfo.RoleID)
        state.fullName = tokenData.userInfo.FullName;
        state.ID = tokenData.userInfo.ID;
      } else {
        state.showRegistrationStep = tokenData.userInfo.registrationStep
        state.completeProfileStep = tokenData
        state.ID = tokenData.userInfo.ID;
        console.log(tokenData)
      }
    },
    setCity: (state, action) => {
      state.citySelect = action.payload.City
    },
    setCourseID: (state, action) => {
      state.appUrlCourseID = action.payload.appUrlCourseID
    },
    setXpLevel: (state, action) => {
      state.xpLevel = action.payload.xpLevel;
    }
  },
  extraReducers: {
    [requestOtp.fulfilled]: (state, action) => {
      const { message, data } = action.payload
      console.log("employer autho token :", action.payload)
      if (message === 'Success') {
        if (data === 'OTP Sent') {
          state.hideSendOtpButton = true
          state.showVerifyOtpForm = true
          state.otpRequestSuccess = true
          state.showEmpVerifyOtpForm = true
          state.newUiCandiateOtpStep = true
          state.message = message;
          state.showEmpLogin = false
          // console.log("mob No: ", mobileNumber);


        } else {
          state.otpRequestError = true
        }
      } else if (message === 'User not found') {
        state.showVerifyOtpForm = false
        state.showRegistrationForm = true
        state.hideSendOtpButton = true
        state.empRegistrationForm = true
        state.showEmpVerifyOtpForm = false
        state.newUiCandidateRegistrationStep = true

      } else {
        state.hideSendOtpButton = false
        state.hideSendOtpButton1 = false
        state.showVerifyOtpForm = false
        state.showRegistrationForm = false
        state.showEmpVerifyOtpForm = false

      }
    },
    // [requestOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [requestOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // },

    [validateOtp.fulfilled]: (state, action) => {
      console.log(state);

      const { data, message } = action.payload
      console.log(action.payload, data, message);
      if (message !== 'Invalid Email OTP' && message !== 'Invalid Mobile OTP') {
        console.log(data)
        const tokenData = jwt_decode(data);
        console.log('tokenData', tokenData?.userInfo?.ID);

        state.candidateAuth = data;
        // state.authToken = data
        if (tokenData.userInfo.registrationStep === 1) {
          console.log('step no 1 triggerd in otp validate');
          state.ID = tokenData?.userInfo?.ID;
          // state.authToken = data
          state.showRegistrationStep = tokenData?.userInfo?.registrationStep
          state.newUiCandidateRegistrationStep = false
          state.newUiCandiateOtpStep = false
          state.newUiCandidateLoginStep = false
        }

        // new ui functionality 
        if (tokenData.userInfo.registrationStep === -1) {
          state.ID = tokenData?.userInfo?.ID;
          state.candidateAuth = data;
          state.newUiCandidateRegistrationStep = true
          state.newUiCandiateOtpStep = false
          state.newUiCandidateLoginStep = false
        }
        // new ui functionality
        if (tokenData.userInfo.registrationStep === -2) {
          console.log('yppppppppppppppppppppppppppppppppppppppppppppppppppppppp');
          state.ID = tokenData?.userInfo?.ID;
          // localStorage.setItem('token', data)
          // state.authToken = data
          state.newUiCandidateExperience = true
          state.newUiCandidateRegistrationStep = false
          state.newUiCandiateOtpStep = false
          state.newUiCandidateLoginStep = false
        }
        if (tokenData.userInfo.registrationStep === 3) {
          state.ID = tokenData?.userInfo?.ID;
          state.newUiCandidateOpportunitiesStep = true
          state.completeProfileStep = tokenData
        }


        if (tokenData.userInfo.registrationStep === 0) {
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
          // state.registerCandidate = false
          // console.log("Register User State:s", registerCandidate)
        }
        else if (tokenData.userInfo.registrationStep === 4) {
          // state.empRegistrationForm = true
          state.showEmpVerifyOtpForm = false
          state.newShowEmpRegistrationForm = true
          state.ID = tokenData?.userInfo?.ID;
          state.showEmpLogin = false
          // state.showEmpRegistrationForm = true
        }
        else if (tokenData.userInfo.registrationStep === 5) {
          console.log("registration setp 5 if loop");
          localStorage.setItem('token', data)
          state.authToken = data
          // state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
          state.showEmpLogin = false
          // state.empRegistrationForm = true
          // state.showVerifyOtpForm = false
          state.showEmpDashboard = false
          // console.log('showEmpDashboard', showEmpDashboard);

        }
        else {
          state.showVerifyOtpForm = false
          state.showEmpVerifyOtpForm = false
          state.showRegistrationForm = true
          state.hideSendOtpButton = true
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          // state.registerCandidate = true
          // console.log("Register User State:", state.registerCandidate)
        }
      } else {
        state.otpValidateError = true
        state.authToken = null
        state.verificationOtpMessage = message
      }

    },


    [addUser.fulfilled]: (state, action) => {
      const { data, message, status } = action.payload
      console.log(action.payload)
      if (message === 'Success') {
        console.log('Registartion Success');
        const tokenData = jwt_decode(data);
        // new ui functionality
        if (tokenData.userInfo.registrationStep === -2) {
          state.ID = tokenData.userInfo.ID;
          state.authToken1 = action.payload.data;

          state.newUiCandidateExperience = true
          state.newUiCandidateRegistrationStep = false
          state.newUiCandiateOtpStep = false
          state.newUiCandidateLoginStep = false
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    },


    [registerUser.fulfilled]: (state, action) => {
      const { data, message, status } = action.payload
      console.log(action.payload)
      if (message === 'Success') {
        console.log('Registartion Success');
        const tokenData = jwt_decode(data);

        // new ui functionality
        if (tokenData.userInfo.registrationStep === -2) {
          state.newUiCandidateExperience = false
          state.newUiCandidateRegistrationStep = false
          state.newUiCandiateOtpStep = false
          state.newUiCandidateLoginStep = false
        }



        if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3) {
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
        }
        else if (tokenData.userInfo.registrationStep === 5) {
          //emp if else is heare after registration complition.
          localStorage.setItem('token', data)
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          // console.log("reg step: ", {showRegistrationStep});
          state.showEmpDashboard = true
          state.showEmpVerifyOtpForm = false
          state.newShowEmpRegistrationForm = false
          state.showEmpLogin = false


        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
      // if(data === 'OTP Sent') {
      //   state.showVerifyOtpForm = true
      //   state.otpRequestSuccess = true
      // } else {
      //   state.otpRequestError = true
      // }
    },

    [submitFormStep1.fulfilled]: (state, action) => {
      console.log(action)
      const { data, status, message } = action.payload
      console.log(data, typeof status, message)
      if (message === 'Success') {
        console.log('ifSuccess')
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 0 || tokenData.userInfo.registrationStep === 3) {
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    },

    [submitFormStep2.fulfilled]: (state, action) => {
      console.log(action)
      const { data, status, message } = action.payload
      console.log(data, typeof status, message)
      if (message === 'Success') {
        console.log('ifSuccess')
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 3) {
          state.newUiCandidateOpportunitiesStep = true
        }
        if (tokenData.userInfo.registrationStep === 0) {
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    },

    [submitFormStep3.fulfilled]: (state, action) => {
      console.log(action)
      const { data, status, message } = action?.payload
      console.log(data, typeof status, message)
      if (message === 'Success') {
        console.log('ifSuccess')
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 0) {
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.fullName = tokenData.userInfo.FullName
          state.ID = tokenData.userInfo.ID;
        }
        else {
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
          console.log(tokenData)
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    },

    [updateRegistrationStep.fulfilled]: (state, action) => {
      console.log("updateRegistrationStep fullfilled")
      const { data, status, message } = action.payload
      console.log(data, typeof status, message)
      if (message === 'Success') {
        console.log('ifSuccess')
        const tokenData = jwt_decode(data);
        if (tokenData.userInfo.registrationStep === 0) {
          console.log('ififSuccess')
          localStorage.setItem('token', data)
          state.authToken = data
          state.otpValidateSuccess = true
          state.role = tokenData.userInfo.RoleID
          state.fullName = tokenData.userInfo.FullName
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          console.log(tokenData.userInfo.registrationStep)
          state.ID = tokenData.userInfo.ID;
        } else if (tokenData.userInfo.registrationStep === -1) {
          console.log('Step No is -1');
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.newUiCandidateExperience = false
          state.ID = tokenData?.userInfo?.ID;
          state.candidateAuth = data;
          state.newUiCandidateRegistrationStep = true
          state.newUiCandiateOtpStep = false
          state.newUiCandidateLoginStep = false
        }
        else if (tokenData.userInfo.registrationStep === -2) {
          console.log('enter in step -2 if condition')
          state.ID = tokenData?.userInfo?.ID;
          state.candidateAuth = data;
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.newUiCandidateExperience = true
          state.newUiCandidateRegistrationStep = false
          state.newUiCandiateOtpStep = false
          state.newUiCandidateLoginStep = false
          state.newUiCandidateEducationStep = false

        }
        else if (tokenData.userInfo.registrationStep === 3) {
          console.log('tokenData', tokenData.userInfo.registrationStep)
        }
        else {
          console.log(tokenData.userInfo.registrationStep)
          state.showRegistrationStep = tokenData.userInfo.registrationStep
          state.completeProfileStep = tokenData
          state.ID = tokenData.userInfo.ID;
        }
      } else {
        console.log('elseSuccess')
        state.otpValidateError = true
        state.authToken = null
      }
    }

    // [validateOtp.pending]: (state, action) => {
    //   state.someValue += action.payload
    // },
    // [validateOtp.rejected]: (state, action) => {
    //   state.someValue += action.payload
    // }
  }
})

export const submitHrComments = createAsyncThunk(
  'auth/submitFormStep1',
  async (params) => {
    const { selectEduLevels, selectEduDegrees, selectXp, selectIncome, selectDomain, stepNo, completeProfileStep } = params;
    const careerData = {
      'education': selectEduLevels,
      'degree': selectEduDegrees,
      'experience': selectXp,
      // 'jobProfile': selectProfile,
      'incomeCTC': selectIncome,
      'domain': selectDomain
    }
    const response = await axios.put(config.apiUrl + '/user/register/' + stepNo + '/' + completeProfileStep.userInfo.ID, { careerData })
    const { data } = response
    return data
  }
)
export const { logout, backToLogin, setToken, setCity, setCourseID, setXpLevel } = authSlice.actions;


export default authSlice.reducer
