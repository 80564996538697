import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap';
import Logo from '../images/ScopeHaiLogo-two-color.png'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { CiCircleMinus } from 'react-icons/ci'
import { GetCandidateByID, fetchAllJobRoles } from '../../features/constants/apis'
import { submitFormStep2, domainJobrolesStep3, updateRegistrationStep } from '../../features/auth/authSlice'
import axios from '../../AxiosConfig'
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import SideValiDidi from './SideValiDidi';

const Opportunity = ({
    ID,
    authToken,
    onSendData,
    submitFormStep3,
    domainJobrolesStep3,
    updateRegistrationStep
}) => {
    console.log('is function imported: ', submitFormStep3);
    console.log('is ID imported: ', ID);

    const [jobRoles, setJobRoles] = useState([])
    const [candidateData, setCandidateData] = useState([])
    const [domainJobRoles, setDomainJobRoles] = useState([])
    useEffect(() => {
        const fetchCandidateByID = async () => {
            const data = await GetCandidateByID(ID, authToken)
            setCandidateData(data.data)
        }
        fetchCandidateByID()
    }, [ID, authToken])

    useEffect(() => {
        const fetchJobRoles = async () => {
            const data = await fetchAllJobRoles(authToken)
            setJobRoles(data.data)
        }
        fetchJobRoles()
    }, [authToken])

    useEffect(() => {
        console.log('CANDIDATE DATA', candidateData)
        console.log('JOB ROLES', jobRoles)
        console.log(jobRoles.filter((jobRoles) => jobRoles.ID == candidateData?.CareerProfile?.domain))
        const DomainNameArr = jobRoles.filter((jobRoles) => jobRoles.ID == candidateData?.CareerProfile?.domain)
        console.log(DomainNameArr[0]?.Domain)
        const DomainName = DomainNameArr[0]?.Domain
        const jobRolesArr = (jobRoles.filter((jobRoles) => jobRoles.Domain == DomainName))
        setDomainJobRoles(jobRolesArr.slice(0, 10))
    }, [candidateData, jobRoles, authToken])

    const handleRemoveJobRole = (ID) => {
        console.log(domainJobRoles)
        let tempNewJobRoleArr = []
        tempNewJobRoleArr = domainJobRoles.filter(item => item.ID !== ID);
        console.log(tempNewJobRoleArr)
        setDomainJobRoles(tempNewJobRoleArr.slice(0, 10))
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    const submitJobRoles = () => {
    }
    console.log('data to insert into the backend:', domainJobRoles)




    const navigate = useNavigate(); // Initialize useNavigate hook

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     try {
    //         const stepNo = 3;
    //         const completeProfileStep = 3;
    //         const response = await axios.put(
    //             `http://localhost:4444/api/user/register/${stepNo}/${ID}`,
    //             domainJobRoles
    //         );
    //         const { data, status, message } = response;
    //         console.log('data', data?.data)
    //         console.log('status', status)
    //         console.log('message', message)
    //         console.log(data, typeof status, message);
    //         if (status === 200) {
    //             console.log('if Success')
    //             const tokenData = jwt_decode(data?.data);
    //             if (tokenData.userInfo.registrationStep === 0) {
    //                 localStorage.setItem('token', data)
    //                 const authToekn = localStorage.getItem('token')
    //                 const role = tokenData.userInfo.RoleID;
    //                 const ID = tokenData.userInfo.ID
    //                 const regStep = tokenData.userInfo.registrationStep
    //                 console.log('authToken', authToekn)
    //                 console.log('role', role)
    //                 console.log('ID', ID)
    //                 console.log('regStep', regStep)
    //                 navigate('/candidate-profile')
    //             }
    //         }
    //         else {
    //             console.log('if condition is not working')
    //         }




    //         // After successful response, navigate to the candidate profile
    //         navigate('/candidate-profile');

    //         console.log('API Response:', response.data);
    //     } catch (error) {
    //         console.error('Error occurred while submitting:', error);
    //     }

    // };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const newStep2RegData = {
            'selectedJobRoles': domainJobRoles,
            'stepNo': '3',
            'completeProfileStep': ID,
        };
        const one = await submitFormStep3(newStep2RegData);
        console.log(one)
        if (one) {
            console.log("Submitting data from child:", newStep2RegData);
            console.log('onSendData', onSendData);
        }

        // Send data to the parent
        // if (onSendData) {
        //     onSendData(newStep2RegData);
        // }
    };



    function handleGoBack() {
        const updateStep = {
            stepNo: 2,
            completeProfileStep: ID
        }
        updateRegistrationStep(updateStep)
    }



    return (
        <section>
            <div style={{ height: '100vh' }}>
                <div className="h-100">
                    <Row className="h-100 w-100">
                        <Col md={8} sm={12} className="opp-col-div h-100">
                            <div>
                                <img src={Logo} style={{ height: '2.5rem' }}></img>
                            </div>
                            <div className="d-flex gap-2 ms-1" style={{ marginTop: '14px' }}>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '8px',
                                        backgroundColor: '#E5E5E5',
                                        borderRadius: '5px'
                                    }}
                                ></div>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '8px',
                                        backgroundColor: '#E5E5E5',
                                        borderRadius: '5px'
                                    }}
                                ></div>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '8px',
                                        backgroundColor: '#E5E5E5',
                                        borderRadius: '5px'
                                    }}
                                ></div>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '8px',
                                        backgroundColor: '#E5E5E5',
                                        borderRadius: '5px'
                                    }}
                                ></div>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '8px',
                                        backgroundColor: '#E5E5E5',
                                        borderRadius: '5px'
                                    }}
                                ></div>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '8px',
                                        backgroundColor: '#358D77',
                                        borderRadius: '5px'
                                    }}
                                ></div>
                            </div>
                            <span class="ms-1" style={{ fontSize: '14px' }}>6 of 6</span>

                            <div className="d-flex flex-column">
                                <h2 className="mb-0 ms-2" style={{ marginTop: '10px' }}>
                                    Opportunities
                                </h2>
                                <lable
                                    className="ms-2"
                                    style={{ color: '#737373', text: 'start' }}
                                >
                                    Review job profiles tailored just for you. Remove the job profiles that don't fit you.
                                </lable>
                            </div>

                            <div class="ms-2 mt-2" style={{ height: '55vh', overflow: 'scroll' }}>
                                <div class="row w-100">
                                    {domainJobRoles?.slice(0, 30).map((v, k) => {
                                        return (
                                            <div class="col-12 col-md-4 mb-2" key={k}>
                                                <div class="card border h-100">
                                                    <div class="card-body p-1 h-100 d-flex flex-column">
                                                        <h3 class="mb-0">{v.Title}</h3>
                                                        <h6 class="mb-0 text-secondary" style={{ marginTop: '3px' }}>Interprting complex datasets for stratigic decisions.</h6>
                                                        <div class="mt-1 mb-1">
                                                            {v?.ProfessionalSkills?.map((v1, k1) => {
                                                                return (
                                                                    <span class="badge border text-black" style={{ marginRight: '3px' }}>{v1}</span>
                                                                )
                                                            })}
                                                        </div>

                                                        <button class="btn btn-outline-danger ps-1 pe-1" style={{ height: '34px', marginTop: 'auto', display: 'block', width: 'fit-content' }} onClick={() => { handleRemoveJobRole(v.ID) }}>
                                                            <span class="text-danger">Remove <CiCircleMinus size={15} /> </span>
                                                        </button>


                                                    </div>
                                                </div>
                                            </div>


                                        )
                                    })}
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="ms-2 mt-1 d-flex justify-content-between">
                                    <div>
                                        <button type="button" onClick={handleGoBack} className="btn btn-outline-primary" style={{ height: '48px', fontSize: '20px' }}><ArrowLeft className="me-1" style={{ height: '15px', width: '15px' }} /> Back <span className="ms-1"></span></button>
                                    </div>
                                    <div>
                                        <Button type='submit' className="btn mb-2" style={{
                                            backgroundColor: '#358D77',
                                            color: 'white',
                                            height: '48px',
                                            fontSize: '20px'
                                        }}>
                                            Finish
                                            <span className="ms-1">
                                                <ArrowRight style={{ height: '15px', width: '15px' }} />
                                            </span>
                                        </Button>
                                    </div>

                                </div>
                            </form>
                        </Col>
                        <Col md={4} sm={12} className='new-ui-otp-col-2'>
                            <SideValiDidi />
                        </Col>
                    </Row>
                </div>
            </div>
        </section>


    )
}

const mapStateToProps = ({ auth }) => {
    const { ID, authToken, } = auth;
    return {
        ID,
        authToken,

    };
};

const mapDispatchToProps = {
    domainJobrolesStep3,
    updateRegistrationStep
};

export default connect(mapStateToProps, mapDispatchToProps)(Opportunity);
