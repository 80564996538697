import React, { useEffect, useState } from 'react'
import { Row, Col, Carousel, Card, Button, Dropdown, InputGroup, Form } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import axios from '../../AxiosConfig'
// import '@splidejs/react-splide/css';
import Logo from '../images/ScopeHaiLogo.png'
import cert1 from '../images/cert1.png'
import cert2 from '../images/cert2.png'
import cert3 from '../images/cert3.png'
import cert4 from '../images/cert4.png'
import job1 from '../images/jobs/comp1.webp'
import job2 from '../images/jobs/comp2.webp'
import job3 from '../images/jobs/comp3.webp'
import job4 from '../images/jobs/comp4.webp'
import { connect } from 'react-redux';
import { setToken } from '../../features/auth/authSlice';
import { fetchPlacementDrives, getAllJobs, getVerifiedCandidates } from '../../features/constants/apis'
import { GetAllApplicationsOfDrive } from '../../features/constants/apis';
import { Link, useNavigate } from "react-router-dom";
import PlacementDriveCard from './Cards/PlacementDriveCard';
import ShimmerJobs from './Shimmer/ShimmerJobs';
import JobListCard from './JobListCard';
import { History, Search, UserCheck, Users } from 'lucide-react';
import VerifyMark from '../images/icon/VerifyMark.svg';
import CircleCheckMark from '../images/icon/CircleCheckMark.svg';
import IconButton from '../images/icon/IconButton.svg';
import { showSearchHistoryOffcanvas } from '../../features/popup/offcanvasSlice';
import moment from 'moment-timezone';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);
// const data = piChardData?.data?.totalCount;

export const horizontalLineData = {
    labels: ['June', 'July', 'August', 'September', 'October', 'November', 'December'], // Horizontal labels
    datasets: [
        {
            label: 'Total Candidates',
            data: [2349, 3587, 4821, 6134, 7460, 8204, 8932], // Data points
            borderColor: 'rgba(37, 99, 235, 1)', // Line color
            backgroundColor: 'rgba(37, 99, 235, 0.5)', // Area under the line color (optional)
            borderWidth: 1, // Line thickness
            fill: false, // Disable filling under the line
            tension: 0.4, // Smooth curve
            pointBackgroundColor: 'rgba(37, 99, 235, 1)', // Dot color
            pointBorderColor: 'rgba(37, 99, 235, 1)',
            pointBorderWidth: 0,
            pointRadius: 4, // Dot size
        },
        {
            label: 'Verified Candidates',
            data: [1354, 1978, 2764, 3347, 4258, 4926, 5378], // Data points
            borderColor: 'rgba(5, 150, 105, 1)', // Line color
            backgroundColor: 'rgba(5, 150, 105, 0.5)', // Area under the line color (optional)
            borderWidth: 1, // Line thickness
            fill: false, // Disable filling under the line
            tension: 0.4, // Smooth curve
            pointBackgroundColor: 'rgba(5, 150, 105, 1)', // Dot color
            pointBorderColor: 'rgba(5, 150, 105, 1)',
            pointBorderWidth: 0,
            pointRadius: 4, // Dot size
        },
    ],
};

const horizontalLineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            position: 'bottom', // Legend below the chart
        },
    },
    scales: {
        x: {
            title: {
                display: true,
                text: 'Months', // X-axis label
            },
            grid: {
                display: false, // Hide vertical grid lines
            },
        },
        y: {
            title: {
                display: true,
                text: 'Visitors', // Y-axis label
            },
            grid: {
                display: true, // Show horizontal grid lines
            },
            beginAtZero: true, // Start Y-axis at 0
        },
    },
};



function EmployerDashboard({ userData, role, fullName, authToken, ID, showSearchHistoryOffcanvas }) {
    const [placementDrives, setPlacementDrives] = useState([])
    const [empPlacementDriveCount, setEmpPlacementDriveCount] = useState(0)

    const [recentDrives, setRecentDrives] = useState([])
    const [recentJobs, setRecentJobs] = useState([])

    const [jobs, setJobs] = useState([])
    const [employerJobCount, setEmployerJobCount] = useState(null)

    const [jobAppliations, setJobApplications] = useState(0);
    const [jobAppliationsCount, setJobApplicationsCount] = useState(0);
    const [piChardData, setPiChartData] = useState();
    const [handleSearch, setHandelSearch] = useState();
    const tempData = [
        {
            "ID": 1,
            "Image": cert1,
            "Name": "Amazon and Flipkart managemet",
            "Title": "Amazon and Flipkart Training and Placement program",
            "Stat1": "Amazon",
            "Stat2": "Flipkart",
            "Stat3": "Training",
            "Stat4": "Placement",
            "Price": "₹8,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 2,
            "Image": cert2,
            "Name": "Video editing",
            "Title": "Video editing and commercial reel making",
            "Stat1": "Videos",
            "Stat2": "Reels",
            "Stat3": "Shorts",
            "Stat4": "Commercials",
            "Price": "₹12,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 3,
            "Image": cert3,
            "Name": "Trading",
            "Title": "Equity markets and Trading trainer certification",
            "Stat1": "Equity",
            "Stat2": "Trading",
            "Stat3": "Financial",
            "Stat4": "Training",
            "Price": "₹6,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 4,
            "Image": cert4,
            "Name": "Data analysis",
            "Title": "Data analysis for E-commerce business",
            "Stat1": "Data analysis",
            "Stat2": "E-commerce",
            "Stat3": "Big data",
            "Stat4": "Business",
            "Price": "₹19,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 5,
            "Image": cert1,
            "Name": "Amazon and Flipkart managemet",
            "Title": "Amazon and Flipkart Training and Placement program",
            "Stat1": "Amazon",
            "Stat2": "Flipkart",
            "Stat3": "Training",
            "Stat4": "Placement",
            "Price": "₹8,999/-",
            "CreatedBy": 1
        },
        {
            "ID": 6,
            "Image": cert2,
            "Name": "Video editing",
            "Title": "Video editing and commercial reel making",
            "Stat1": "Videos",
            "Stat2": "Reels",
            "Stat3": "Shorts",
            "Stat4": "Commercials",
            "Price": "₹12,999/-",
            "CreatedBy": 1
        }
    ]
    const [employerDashboardData, setEmployerDashboardData] = useState(null)
    const [verifiedUserChart, setVerifiedUserChart] = useState(null)
    const [verifiedUsersOptions, setVerifiedUsersOptions] = useState(null)

    useEffect(async () => {
        const verifiedCandidateResponse = await getVerifiedCandidates(authToken)
        console.log('response for new api: ', verifiedCandidateResponse?.data);

        setPiChartData(verifiedCandidateResponse);

        const empDashboard = await axios.get(`/employeedashboarddata/${ID}`);
        console.log('EMPLOYER DASHBOARD DATA', empDashboard.data.data)
        setEmployerDashboardData(empDashboard.data.data)
        // http://localhost:3005/api/employeedashboarddata/5
    }, [])

    const navigate = useNavigate();
    const handleViewNewDriveForm = () => {
        navigate(`/new-placement-drive-form`)
    }
    const handleViewNewJobForm = () => {
        navigate(`/new-job-form`)
    }
    useEffect(() => {
        if (employerDashboardData) {
            setVerifiedUserChart({
                datasets: [
                    {
                        label: '# of Visitors',
                        data: [parseInt(employerDashboardData?.hiringProcessStatusCounts?.shortlisted), parseInt(employerDashboardData?.searchLogs.length)],
                        backgroundColor: [
                            'rgba(5, 150, 105, 1)',
                            'rgba(229, 229, 229, 1)'
                        ],
                        borderColor: [
                            'rgba(5, 150, 105, 1)',
                            'rgba(229, 229, 229, 1)'
                        ],
                        borderWidth: 1,
                    },
                ],
                labels: ['Shortlisted', 'Searched']
            });
            
            setVerifiedUsersOptions({
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom', // Position the legend below the chart
                    },
                },
                cutout: '70%',
                maintainAspectRatio: false,
                responsive: true
            });
        }
    }, [employerDashboardData])

    useEffect(() => {
        const fetchData = async () => {
            const PDdata = await fetchPlacementDrives(authToken);
            setPlacementDrives(PDdata.data);
            console.log(PDdata.data)

            const empPlacementDrive = PDdata.data.filter(drive => drive?.CreatedBy === ID);
            setEmpPlacementDriveCount(empPlacementDrive.length);
            // console.log(empPlacementDriveCount)
            console.log(empPlacementDrive[0]?.CreatedOn)

            const today = new Date()

            const recentlyAddedDrives = empPlacementDrive.filter(drive => {
                const createdDate = new Date(drive?.CreatedOn);
                console.log(createdDate);

                const timeDifference = today - createdDate;
                const dayDifference = timeDifference / (1000 * 3600 * 24);
                console.log(dayDifference);

                return dayDifference <= 15;
            });

            setRecentDrives(recentlyAddedDrives);
            console.log(recentlyAddedDrives)
        }
        fetchData();
    }, [authToken, ID, empPlacementDriveCount]);

    useEffect(() => {
        const fetchPDApplications = async () => {
            const data = await GetAllApplicationsOfDrive(authToken)
            setJobApplications(data.data)

            const applicationData = data.filter(application => application?.EmployerID === ID);
            setJobApplicationsCount(applicationData.length);
        }
        fetchPDApplications()
    }, [authToken, ID])

    const handleViewDriveDetails = (drive) => {
        // setSelectedDrive(drive);
        navigate(`/emp-placement-drive-details?drive=${drive}`)
    }

    const handleShowSearchHistoty = () => {
        showSearchHistoryOffcanvas({ searchHistoryOffcanvasVisible: true, searchData: employerDashboardData });
    }
    const handleCourseDetail = (courseDetailUrl) => {
        window.open('https://scopehai.com/courses/' + courseDetailUrl, '_blank');
    }
    const handleSearchHistory = (searchHistoryData) => {
        console.log(searchHistoryData)
        navigate(`/emp-candidate-list?term=${searchHistoryData.Filters.term}&sortBy[]=${searchHistoryData.Filters.sortBy[0]}&pageSize=${searchHistoryData.Filters.pageSize}&pageIndex=${searchHistoryData.Filters.pageIndex}&isVsp=${searchHistoryData.Filters.isVsp}&year=${searchHistoryData.Filters.year}&salary=${searchHistoryData.Filters.salary}&maxCtc=${searchHistoryData.Filters.maxCtc}&immediate=${searchHistoryData.Filters.immediate}&location=${searchHistoryData.Filters.location}&fullTime=${searchHistoryData.Filters.fullTime}&partTime=${searchHistoryData.Filters.partTime}&internship=${searchHistoryData.Filters.internship}&remote=${searchHistoryData.Filters.remote}&onSite=${searchHistoryData.Filters.onSite}&searchTitle=${searchHistoryData.Filters.searchTitle}`)
    }
    const redirectToSearchResults = (keyword) => {
        navigate(`/emp-candidate-list?term=${keyword}`)
    }
    return (
        <div id="appCapsule" className='Dashboard' style={{ background: '#f5f5f5' }}>
            {/* Dashboard Count View Start */}
            <div className='section full pb-4 pt-0'>
                <div className='container'>
                    <div class="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <h1 className="title mb-0">Dashboard</h1>
                        {/* <Dropdown style={{ border: '1px solid gainsboro', borderRadius: '6px' }}>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                Last 6 Months&nbsp;&nbsp;<ion-icon name="chevron-down-outline" class="me-0 fs-6"></ion-icon>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Today</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Yesterday</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Last 3 Months</Dropdown.Item>
                                <Dropdown.Item href="#/action-4">Last 6 Months</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card shadow-none border">
                                <Link to='/all-emp-jobs'>
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col" style={{ height: '80px' }}>
                                        <div className='db-bg-icon order-sm-1 border-0' style={{ background: '#DBEAFE' }}>
                                            <Search style={{ color: '#2563EB' }} />
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title mb-0">{(employerDashboardData?.searchLogs.length > 0) ? employerDashboardData?.searchLogs.length : 0}</h5>
                                            <h4>Searches</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card shadow-none border">
                                <Link to='/emp-placement-drives'>
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col" style={{ height: '80px' }}>
                                        <div className='db-bg-icon order-sm-1 border-0' style={{ background: '#F3E8FF' }}>
                                            <Users style={{ color: '#9333EA' }} />
                                        </div>
                                        <div className='order-sm-0'>
                                            {/* <h5 className="card-title">{empPlacementDriveCount}</h5> */}
                                            <h5 className="card-title mb-0">{(employerDashboardData?.candidatesViewed > 0) ? employerDashboardData?.candidatesViewed : 0}</h5>
                                            <h4>Candidates Viewed</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card shadow-none border">
                                <Link to='/all-emp-applications'>
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col" style={{ height: '80px' }}>
                                        <div className='db-bg-icon order-sm-1 border-0' style={{ background: '#DCFCE7', fontSize: '24px' }}>
                                            {/* <FaStar style={{ color: '#16A34A' }} /> */}
                                            <ion-icon name="star-outline" style={{ color: '#16A34A' }}></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            {/* <h5 className="card-title mb-0">{jobAppliationsCount}</h5> */}
                                            <h5 className="card-title mb-0">{(employerDashboardData?.hiringProcessStatusCounts?.shortlisted > 0) ? employerDashboardData?.hiringProcessStatusCounts?.shortlisted : 0}</h5>
                                            <h4>Candidates Shortlisted</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card shadow-none border">
                                <Link to='/all-emp-applications'>
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col" style={{ height: '80px' }}>
                                        <div className='db-bg-icon order-sm-1 border-0' style={{ background: '#FEF3C7' }}>
                                            <UserCheck style={{ color: '#D97706' }} />
                                        </div>
                                        <div className='order-sm-0'>
                                            {/* <h5 className="card-title mb-0">{jobAppliationsCount}</h5> */}
                                            <h5 className="card-title mb-0">{(employerDashboardData?.hiringProcessStatusCounts?.hired > 0) ? employerDashboardData?.hiringProcessStatusCounts?.hired : 0}</h5>
                                            <h4>Candidates Hired</h4>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <InputGroup className="mb-1 me-1 shadow">
                                <InputGroup.Text id="basic-addon1" className='w-auto ps-1' style={{ background: '#ffffff', border: '1px solid gainsboro', height: '44px' }}>
                                    <ion-icon name="search-outline"></ion-icon>
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="Search for a job position"
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => { setHandelSearch(e.target.value) }}
                                    value={handleSearch}
                                    style={{ borderLeft: 'none', borderColor: 'gainsboro', borderRight: 'none', height: '44px' }}
                                />
                                <InputGroup.Text id="basic-addon1" className='w-auto pe-1' onClick={() => {redirectToSearchResults(handleSearch)}} style={{ background: '#ffffff', border: '1px solid gainsboro', borderLeft: 'none', cursor: 'pointer', height: '44px' }}>
                                    <ion-icon name="chevron-forward-outline" class="text-primary"></ion-icon>
                                </InputGroup.Text>
                            </InputGroup>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12 col-md-4 mb-2">
                            <Card style={{ boxShadow: 'none', height: '100%' }}>
                                <Card.Body>
                                    <h3 class="d-flex align-items-center"><History size={18} />&nbsp;&nbsp;Past Searches</h3>
                                    {(employerDashboardData?.searchLogs.length > 0) &&
                                        <ul class="p-0 mt-2 mb-0" style={{ listStyleType: 'none' }}>
                                            {employerDashboardData?.searchLogs?.map((v1,k1) => {
                                                return (
                                                    <>
                                                        {(k1 <= 4) &&
                                                            <li class="border-bottom cursor-pointer" style={{ padding: '7px 0px 0px 7px' }} onClick={() => {handleSearchHistory(v1)}}>
                                                                <div class="row">
                                                                    <div class="col-8 col-md-8">
                                                                        <h6 class="mb-0">{v1.Filters.searchTitle}</h6>
                                                                        {/* <p class="fs-10 text-secondary mb-0" style={{ marginTop: '-2px' }}>4 Filters applied</p> */}
                                                                        <p class="fs-10 text-secondary mb-0" style={{ marginTop: '-2px' }}>Page no {v1.Filters.pageIndex}</p>
                                                                    </div>
                                                                    <div class="col-4 col-md-4 d-flex justify-content-end align-items-center">
                                                                        <p class="fs-10 text-secondary mb-0 text-nowrap" style={{ marginTop: '-2px' }}>{moment.utc(v1.CreatedOn).tz('Asia/Kolkata').fromNow()}</p>&nbsp;&nbsp;&nbsp;
                                                                        <ion-icon name="chevron-forward-outline" class="text-secondary" style={{ marginTop: '-2px' }}></ion-icon>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        }
                                                    </>
                                                )
                                            })}
                                            <li class="d-flex align-items-center justify-content-center" onClick={() => { handleShowSearchHistoty() }} style={{ padding: '20px 0px 5px 0px' }}>
                                                <h6 class="mb-0 text-primary cursor-pointer">View Search History</h6>
                                            </li>
                                        </ul>
                                    }
                                    {(employerDashboardData?.searchLogs.length == 0) &&
                                        <h4 class="text-danger text-center">No Search History</h4>
                                    }
                                </Card.Body>
                            </Card>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <Card style={{ boxShadow: 'none', height: '100%' }}>
                                <Card.Body>
                                    <h3 class="d-flex align-items-center"><img src={VerifyMark} width="18" />&nbsp;&nbsp;Shortlisted Candidates</h3>
                                    <div class="h-auto d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
                                        <div class="d-flex align-items-center justify-content-center flex-column text-center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -80%)' }}>
                                            <h2 class="mb-0">{(employerDashboardData?.hiringProcessStatusCounts?.shortlisted > 0) ? employerDashboardData?.hiringProcessStatusCounts?.shortlisted : 0} of {(employerDashboardData?.searchLogs.length > 0) ? employerDashboardData?.searchLogs.length : 0}</h2>
                                            <p class="mb-0 fs-6">Shortlisted</p>
                                        </div>
                                        {verifiedUserChart &&
                                            <Doughnut data={verifiedUserChart} options={verifiedUsersOptions} width={250} height={250} />
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <Card style={{ boxShadow: 'none', height: '100%' }}>
                                <Card.Body>
                                    <h3 class="d-flex align-items-center"><img src={CircleCheckMark} width="18" />&nbsp;&nbsp;Recent Courses</h3>
                                    <ul class="p-0 mt-2 mb-0" style={{ listStyleType: 'none' }}>
                                        {employerDashboardData?.courses?.map((v,k) => {
                                            return (
                                                <li class="border-bottom cursor-pointer" style={{ padding: '7px 0px 0px 7px' }} onClick={() => handleCourseDetail(v.Url)}>
                                                    <div class="row">
                                                        <div class="col-2 col-md-2 pe-0">
                                                            <div class="w-100" style={{ height: '30px', backgroundImage: `url(${IconButton})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '3px' }}>
                                                            </div>
                                                        </div>
                                                        <div class="col-9 col-md-9">
                                                            <h6 class="mb-0 w-100 text-nowrap" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{v.Name}</h6>
                                                            <p class="fs-10 text-secondary mb-0 text-nowrap" style={{ marginTop: '-2px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{v.Stat1}</p>
                                                        </div>
                                                        <div class="col-1 col-md-1 d-flex justify-content-end align-items-center ps-0">
                                                            <ion-icon name="chevron-forward-outline" class="text-secondary" style={{ marginTop: '-2px' }}></ion-icon>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                        {/* <li class="d-flex align-items-center justify-content-center" style={{ padding: '20px 0px 5px 0px' }}>
                                            <h6 class="mb-0 text-primary cursor-pointer">View All Courses</h6>
                                        </li> */}
                                    </ul>
                                </Card.Body>
                            </Card>
                        </div>
                        <div class="col-12 col-md-12">
                            <Card style={{ boxShadow: 'none' }}>
                                <Card.Body>
                                    <h3 class="d-flex align-items-center"><Users size={18} />&nbsp;&nbsp;Candidates Onboarded&nbsp;&nbsp;<span class="text-primary">8932</span></h3>
                                    <div>
                                        <Line data={horizontalLineData} options={horizontalLineOptions} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
            {/* Dashboard Count View End */}
            {/* User Profile Start */}
            <div className="section full mt-2 mb-4 d-none">
                <div className='container'>
                    <h1 className="title">Hi, {fullName}</h1>
                    <h2 className='sub-title'>Welcome to your Employer Dashboard !</h2>
                    <p>Here is how your profile is performing on Scopehai Talent platform.</p>
                    <Button variant="primary" size="lg" className='me-2 mb-1' onClick={handleViewNewDriveForm}>New Placement Drive</Button>
                    <Button variant="outline-primary" size="lg" className="mb-1" onClick={handleViewNewJobForm}>Start Hiring</Button>
                    {/* <Row>
                        <Col className='d-flex justify-content-center'>
                            <Button variant="primary" size="lg">New Placement</Button>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Button variant="outline-primary" size="lg">Start Hiring</Button>
                        </Col>
                    </Row> */}
                </div>
            </div>
            {/* User Profile End */}

            {recentDrives.length > 0 && (
                <div className="section full mt-2 mb-4 py-4">
                    <div className='container'>
                        <h2>Recent Placement Drives</h2>
                        {/* <Row xs={1} md={2} className="g-4"> */}
                        <Splide
                            options={{
                                rewind: false,
                                gap: '.5rem',
                                perMove: 1,
                                perPage: 2,
                                type: 'slide',
                                autoplay: false,
                                interval: 3000,
                                breakpoints: {
                                    875: {
                                        perPage: 2
                                    },
                                    585: {
                                        perPage: 1
                                    }
                                }
                            }}
                            aria-label="React Splide Example">
                            {recentDrives.map((drive, idx) => (
                                <SplideSlide>
                                    <PlacementDriveCard key={idx} drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                                </SplideSlide>
                            ))}
                        </Splide>
                        {/* </Row> */}
                    </div>
                </div>
            )}

            {recentJobs.length > 0 && (
                <div className="section full mt-2 mb-4 py-4">
                    <div className='container'>
                        <h2>Recent Jobs</h2>
                        {recentJobs.length === null ? <ShimmerJobs />
                            :
                            <JobListCard
                                allJobs={recentJobs}
                            />
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
    setToken,
    showSearchHistoryOffcanvas
}


export default connect(mapStateToProps, mapDispatchToProps)(EmployerDashboard)